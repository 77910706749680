import React, {
  useState,
  useReducer,
  useCallback,
  Fragment,
  useEffect,
} from "react";
import { makeStyles, alpha } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import timezones from "timezones-list";

import ContactMailIcon from "@material-ui/icons/ContactMail";
import GroupIcon from "@material-ui/icons/Group";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import PublishIcon from "@material-ui/icons/Publish";

import AllocationsByMail from "./AllocationsByMail";
import AllocationsByTeam from "./AllocationsByTeam";
import AllocationsByPartner from "./AllocationsByPartner";
import BulkUpload from "../teamAllocations/BulkUpload";
import AllocationRecordsTable from "./AllocationRecordsTable";
import {
  AppConfig,
  DefaultAllocationStatus,
  AllocationStatus,
  RequestStatus,
} from "../../Config";
import {
  formatDateWithDashes,
  isPastDateForAlloc,
  getAllowedPastDateForDefAlloc,
  getAllowedDefaultDate,
  getTimeFromDate,
  getDateFromString,
  isPastDateForDefAlloc,
  getDateObjectFromTimeString,
  isPaidEngagement,
  isEngineeringAlloc,
  getDateObjectFromString,
  getTimeWithoutSecs,
  getTimeZoneDisp,
  getTimeZoneObj,
  getBaseTime,
  getTimeByTimezone,
  getEndDateTime,
  validateUserInput,
} from "../utils/utils";
import {
  ENGAGEMENTS,
  DIALOG_TYPES,
  EO_ALLOCATION_TYPE,
  DEFAULT_TIMEZONE,
  PRE_ENGAGEMENT_RELAX_PERIOD_DAYS,
  POST_ENGAGEMENT_RELAX_PERIOD_DAYS,
  POST_ENGAGEMENT_OVERHEAD_DAYS,
  PRE_ENGAGEMENT_OVERHEAD_DAYS,
  TIMELINE_RANGE_EXTRA_DAYS,
  INPUT_INVALID_MSG,
  ALLOWED_PAST_DAYS_DEF_ALLOC,
  ALLOWED_PAST_DAYS_ALLOC,
  ALLOCATION_TABLE_COLUMN_FIELD_MAP,
  DEFAULT_TABLE_PAGE_SIZE,
  DEFAULT_TABLE_OFFSET,
  SORT_ORDER,
} from "../utils/AppConstants";
import ConfirmationDialog from "../ConfirmationDialog";
import useHttp from "../utils/http";
import BackdropProgress from "../BackdropProgress";
import { countries } from "../utils/AppData";

import Portal from "@material-ui/core/Portal";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../Alert";

import AddNewAllocationPopup from "./AddNewAllocationPopup";
import { useAllocationTypes } from "../DataContext";
import { getUserName } from "../utils/oauth";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    //boxShadow: theme.shadows[5],
    boxShadow: "0px 3px 12px #00000017",
    padding: theme.spacing(2, 4, 3),
  },
  createNewButton: {
    paddingRight: 8,
    paddingLeft: 8,
  },
  page: {
    width: "100%",
    // padding: 20,
    // paddingLeft: 0,
    // paddingRight: 0
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#ff7300",
    },
  },
  tab: {
    textTransform: "uppercase",
    color: "#ff7300",
    "&.Mui-selected": {
      color: "#ff7300",
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },
  },
}));

const tabProps = (index) => {
  return {
    id: `popup-nav-tab-${index}`,
    "aria-controls": `popup-nav-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tab-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const ACTIONS = {
  ADD_NEW_ALLOCATION: "ADD_NEW_ALLOCATION",
  UPDATE_ALLOCATION_STATUS: "UPDATE_ALLOCATION_STATUS",
  DELETE_ALLOCATION: "DELETE_ALLOCATION",
  ALLOCATION_WITH_OVERLAPS: "ALLOCATION_WITH_OVERLAPS",
  UPDATE_REQUEST_STATUS: "UPDATE_REQUEST_STATUS",
  UPDATE_TIMELINE_ON_OVERLAP: "UPDATE_TIMELINE_ON_OVERLAP",
};

const AllocationsBy = ({
  data,
  engagementId,
  isInternalEngagement,
  isMarketingEvent,
  initRequestStatus,
  isByMailEnabled = true,
  isByTeamEnabled = true,
  isBulkUploadEnabled = false,
  isSearchByAllocTypeEnabled = false,
  isTravelRequiredAvail,
  isAllocTypeSelect = false,
  isEngagementDataPanelAvail = false,
  allocRecordsTableAvail = false,
  searchByMailHeader,
  searchByTeamHeader,
  bulkUploadHeader,
  searchByPartnerHeader,
  isSearchByDatesEnabled,
  isMainTabs = true,
  isTeamAllocPage,
  isByPartnerEnabled = false,
  headerActionBtnVisible,
  getEngagementData,
  isTimelineTimeZoneEnabled = false,
}) => {
  // const AllocationsBy = ({ header, data, isHourlyBasis, isByMailEnabled = true, isByTeamEnabled = true, isBulkUploadEnabled = false, isSearchByAllocTypeEnabled = false, isTravelRequiredAvail, isAllocTypeSelect = false, isEngagementDataPanelAvail = false }) => {
  const classes = useStyles();
  const MILLISECONDS_FOR_DAY = 1000 * 60 * 60 * 24;

  let allocationTypes = useAllocationTypes();

  let initSelectedAllocationData = {
    email: "",
    allocationTypeObj: "",
    startDate: null,
    endDate: null,
    allocationStatus: "",
    comment: "",
    engagementCode: "",
    isTravelRequired: "",
    status: "",
    addedBy: "",
    engagementId: "",
    clearanceStatus: "",
    sfAllocationId: "",
    roleConsultant: "",
    startTime: null,
    endTime: null,
    timeZoneObj: null,
    isRecurring: "",
    engagementTypeId: "",
    isTentativeAllocation: false,
  };

  const [tab, setTab] = useState(0);
  const [isHourlyBasis, setIsHourlyBasis] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGetAllocationsInProgress, setIsGetAllocationsInProgress] =
    useState(false);
  const [isSubmitBtnClicked, setIsSubmitBtnClicked] = useState(false); // For add new allocation / update allocation

  const { handleRequest } = useHttp();

  const [tabIndexes, setTabIndexes] = useState({
    byMailTab: 0,
    byTeamTab: 1,
    bulkUploadTab: 2,
    byPartnerTab: 3,
  });

  const [selectedEmail, setSelectedEmail] = useState("");
  // const [selectedPartner, setSelectedPartner] = useState("");

  const [selectedEmails, setSelectedEmails] = useState([{}]);
  // const [selectedPartners, setSelectedPartners] = useState([{}]);

  const [prevClearanceStatus, setPrevClearanceStatus] = useState("");

  const [allocationsItemList, setAllocationsItemList] = useState([]); // allocation items for timeline
  const [allocationsItemListTeam, setAllocationsItemListTeam] = useState([]); // allocation items for search by team timeline
  const [allocationsItemListPartner, setAllocationsItemListPartner] = useState(
    []
  ); // allocation items for search by partner timeline

  // const [defaultAllocationTypes, setDefaultAllocationTypes] = useState([]);
  const [isHourlyBasisDisabled, setIsHourlyBasisDisabled] = useState(false);

  const [emailAllocationDetailsMap, setEmailAllocationDetailsMap] = useState(
    {}
  ); // For team allocations

  const [addedAllocationsList, setAddedAllocationsList] = useState([]);

  const [requestStatus, setRequestStatus] = useState(initRequestStatus);
  const [statusList, setStatusList] = useState([]);
  const [selectedAllocationData, setSelectedAllocationData] = useState({
    ...initSelectedAllocationData,
    ...data,
  });

  const [timelineTimeZoneObj, setTimelineTimeZoneObj] = useState(
    timezones.find((timezone) => timezone.tzCode === DEFAULT_TIMEZONE)
  );
  const [timelineStartDate, setTimelineStartDate] = useState(null);
  const [timelineEndDate, setTimelineEndDate] = useState(null);

  // For engagement allocation validation purposes
  const [originalTimelineStartDate, setOriginalTimelineStartDate] =
    useState(null);
  const [originalTimelineEndDate, setOriginalTimelineEndDate] = useState(null);

  const [updatePageAuto, setUpdatePageAuto] = useState(false);

  const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
  const [alertMessageType, setAlertMessageType] = useState("error");
  const [alertMessage, setAlertMessage] = useState("");

  const [isResetPageIndex, setIsResetPageIndex] = useState(false);
  const [tablePageSize, setTablePageSize] = useState(DEFAULT_TABLE_PAGE_SIZE);
  const [filterText, setFilterText] = useState("");
  const [sortBy, setSortBy] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const allocationReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.ADD_NEW_ALLOCATION:
        return {
          ...state,
          allocationsPopupDetails: action.allocationsPopupDetails,
          title: "Add New Allocation",
          isAllocationStatusEdit: false,
        };

      case ACTIONS.UPDATE_ALLOCATION_STATUS:
        return {
          ...state,
          allocationsPopupDetails: action.allocationsPopupDetails,
          title: "Update Allocation Status",
          isAllocationStatusEdit: true,
        };

      case ACTIONS.DELETE_ALLOCATION:
        return {
          ...state,
          confirmDialogDetails: action.confirmDialogDetails,
          dialogType: DIALOG_TYPES.CONFIRM_DIALOG,
        };

      case ACTIONS.ALLOCATION_WITH_OVERLAPS:
        return {
          ...state,
          confirmDialogDetails: action.confirmDialogDetails,
          dialogType: DIALOG_TYPES.CONFIRM_DIALOG,
        };
      case ACTIONS.UPDATE_REQUEST_STATUS:
        return {
          ...state,
          confirmDialogDetails: action.confirmDialogDetails,
          dialogType: DIALOG_TYPES.CONFIRM_DIALOG,
        };

      case ACTIONS.UPDATE_TIMELINE_ON_OVERLAP:
        return {
          ...state,
          confirmDialogDetails: action.confirmDialogDetails,
          dialogType: DIALOG_TYPES.YES_NO_DIALOG,
        };

      default:
        throw new Error("Should not get here");
    }
  };

  const [
    {
      confirmDialogDetails,
      dialogType,
      allocationsPopupDetails,
      title,
      isAllocationStatusEdit,
    },
    dispatchForm,
  ] = useReducer(allocationReducer, {
    confirmDialogDetails: { open: false },
    dialogType: "",
    allocationsPopupDetails: {},
    title: "",
    isAllocationStatusEdit: false,
  });

  const getStatusList = (clearanceStatus) => {
    switch (clearanceStatus) {
      case AllocationStatus.TENTATIVE:
        return [
          AllocationStatus.READY_FOR_CLEARANCE,
          AllocationStatus.CLEARANCE_IN_PROGRESS,
          AllocationStatus.CONFIRMED,
          AllocationStatus.CONFIRMED_VISA_PENDING,
          AllocationStatus.ALLOCATION_CANCELLED,
        ];
      case AllocationStatus.READY_FOR_CLEARANCE:
        return [
          AllocationStatus.CLEARANCE_IN_PROGRESS,
          AllocationStatus.CONFIRMED,
          AllocationStatus.CONFIRMED_VISA_PENDING,
          AllocationStatus.ALLOCATION_CANCELLED,
        ];
      case AllocationStatus.CLEARANCE_IN_PROGRESS:
        return [
          AllocationStatus.ACCEPTED_BY_CONSULTANT,
          AllocationStatus.REJECTED_BY_CONSULTANT,
          AllocationStatus.ALLOCATION_CANCELLED,
        ];
      case AllocationStatus.ACCEPTED_BY_CONSULTANT:
        return [
          AllocationStatus.CONFIRMED,
          AllocationStatus.CONFIRMED_VISA_PENDING,
          AllocationStatus.REJECTED_BY_CONSULTANT,
          AllocationStatus.ALLOCATION_CANCELLED,
        ];
      case AllocationStatus.CONFIRMED:
        return [
          AllocationStatus.REJECTED_BY_CONSULTANT,
          AllocationStatus.REJECTED_VISA_ISSUES,
          AllocationStatus.REJECTED_OTHER,
          AllocationStatus.ALLOCATION_CANCELLED,
        ];
      case AllocationStatus.CONFIRMED_VISA_PENDING:
        return [
          AllocationStatus.CONFIRMED,
          AllocationStatus.REJECTED_VISA_ISSUES,
          AllocationStatus.REJECTED_BY_CONSULTANT,
          AllocationStatus.ALLOCATION_CANCELLED,
        ];
      default:
        return [
          AllocationStatus.CLEARANCE_IN_PROGRESS,
          AllocationStatus.CONFIRMED,
          AllocationStatus.CONFIRMED_VISA_PENDING,
          AllocationStatus.ALLOCATION_CANCELLED,
        ];
    }
  };

  useEffect(() => {
    let allocationDetails = [];
    let newAllocationDetails = [];
    let newAllocation;

    if (tab === tabIndexes.byTeamTab) {
      allocationDetails = [...allocationsItemListTeam];
    } else if (tab === tabIndexes.byPartnerTab) {
      allocationDetails = [...allocationsItemListPartner];
    } else {
      allocationDetails = [...allocationsItemList];
    }

    allocationDetails.forEach((allocation) => {
      if (allocation.isHourly) {
        let startTimeForTimezone = getTimeByTimezone(
          new Date(allocation.start_time),
          allocation.timeZoneObj,
          timelineTimeZoneObj
        );
        let endTimeForTimezone = getTimeByTimezone(
          new Date(allocation.end_time),
          allocation.timeZoneObj,
          timelineTimeZoneObj
        );

        newAllocation = {
          ...allocation,
          start_time: startTimeForTimezone,
          end_time: endTimeForTimezone,
          timeZoneObj: timelineTimeZoneObj,
          tooltipExtra: [
            `Daily Start Time: ${getTimeFromDate(startTimeForTimezone)} (${timelineTimeZoneObj && getTimeZoneDisp(timelineTimeZoneObj.tzCode)})`,
            `Daily End Time: ${getTimeFromDate(endTimeForTimezone)} (${timelineTimeZoneObj && getTimeZoneDisp(timelineTimeZoneObj.tzCode)})`,
          ].join("\n"),
        };
      } else {
        newAllocation = {
          ...allocation,
        };
      }

      newAllocationDetails.push(newAllocation);
    });

    if (tab === tabIndexes.byTeamTab) {
      setAllocationsItemListTeam(newAllocationDetails);
    } else if (tab === tabIndexes.byPartnerTab) {
      setAllocationsItemListPartner(newAllocationDetails);
    } else {
      setAllocationsItemList(newAllocationDetails);
    }
  }, [timelineTimeZoneObj, tabIndexes, tab]);

  useEffect(() => {
    if (data.startDate && data.endDate) {
      let timeLineStart = new Date(data.startDate.getTime());
      let timeLineEnd = new Date(data.endDate.getTime());

      if (!isTeamAllocPage) {
        timeLineStart.setDate(
          timeLineStart.getDate() -
            (PRE_ENGAGEMENT_RELAX_PERIOD_DAYS +
              PRE_ENGAGEMENT_OVERHEAD_DAYS +
              TIMELINE_RANGE_EXTRA_DAYS)
        );
        timeLineEnd.setDate(
          timeLineEnd.getDate() +
            (POST_ENGAGEMENT_RELAX_PERIOD_DAYS +
              POST_ENGAGEMENT_OVERHEAD_DAYS +
              TIMELINE_RANGE_EXTRA_DAYS)
        );

        setOriginalTimelineStartDate(new Date(timeLineStart));
        setOriginalTimelineEndDate(new Date(timeLineEnd));
      }

      setTimelineStartDate(new Date(timeLineStart));
      setTimelineEndDate(new Date(timeLineEnd));
    }
  }, [isTeamAllocPage, data.startDate, data.endDate]);

  useEffect(() => {
    // This is to handle the scenario where the user refreshes the page; we're retreiving allocationTypes again in that scenario
    // The issue is, if allocationTypes are not available when the addedAllocationsList is set,
    // then the allocationTypeObj is not set for the allocations in the addedAllocationsList
    // Therefore, we're setting the allocationTypeObj for the allocations once the allocationTypes are available
    // It is assumed that if the first element of addedAllocationsList has allocationTypeObj, then all the elements have it.
    if (
      allocationTypes?.length > 0 &&
      addedAllocationsList?.length > 0 &&
      !addedAllocationsList[0].allocationTypeObj
    ) {
      let updatedAllocationsList = [];
      let updatedAllocation;
      let allocationTypeObj;

      addedAllocationsList.forEach((allocation) => {
        if (!allocation.allocationTypeObj) {
          allocationTypeObj = allocationTypes.find(
            (type) => type.id === allocation.allocationType
          );

          updatedAllocation = {
            ...allocation,
            allocationTypeObj: allocationTypeObj,
          };
          updatedAllocationsList.push(updatedAllocation);
        }
      });

      setAddedAllocationsList(updatedAllocationsList);
    }
  }, [allocationTypes, addedAllocationsList]);

  useEffect(() => {
    setRequestStatus(initRequestStatus);
  }, [initRequestStatus]);

  useEffect(() => {
    if (selectedEmail) {
      getAllocationsByEmail(
        [selectedEmail.id],
        timelineStartDate,
        timelineEndDate
      );
    }
  }, [selectedEmail]);

  useEffect(() => {
    let initIndex = 0;
    let byMailIndex = -1;
    let byTeamIndex = -1;
    let bulkUploadIndex = -1;
    let byPartnerIndex = -1;

    if (isByMailEnabled) {
      byMailIndex = initIndex;
      initIndex++;
    }

    if (isByTeamEnabled) {
      byTeamIndex = initIndex;
      initIndex++;
    }

    if (isBulkUploadEnabled) {
      bulkUploadIndex = initIndex;
      initIndex++;
    }

    if (isByPartnerEnabled) {
      byPartnerIndex = initIndex;
    }

    setTabIndexes({
      byMailTab: byMailIndex,
      byTeamTab: byTeamIndex,
      bulkUploadTab: bulkUploadIndex,
      byPartnerTab: byPartnerIndex,
    });
  }, [
    isByMailEnabled,
    isByTeamEnabled,
    isBulkUploadEnabled,
    isByPartnerEnabled,
  ]);

  const getAllocationsByEmail = useCallback(
    async (
      emails,
      selectedStartDate,
      selectedEndDate,
      selectedAllocationTypes,
      callbackFn,
      replaceExistingMap
    ) => {
      if (emails && emails.length > 0 && selectedStartDate && selectedEndDate) {
        getAllocations(
          emails,
          selectedStartDate,
          selectedEndDate,
          selectedAllocationTypes && selectedAllocationTypes.length > 0
            ? selectedAllocationTypes.map((typeObj) => typeObj.id)
            : [],
          (allocationDetails, allocationDetailsMap) => {
            setIsLoading(true);

            if (isTeamAllocPage) {
              if (replaceExistingMap) {
                setEmailAllocationDetailsMap({
                  ...allocationDetailsMap,
                });
              } else {
                setEmailAllocationDetailsMap((prevMap) => {
                  return {
                    ...prevMap,
                    ...allocationDetailsMap,
                  };
                });
              }
            }

            // Since we obtained the allocations for the email, we remove those items from timeline and set the new ones obtained (to avoid duplicates)
            if (tab === tabIndexes.byTeamTab) {
              setAllocationsItemListTeam((allocationsList) => {
                return allocationsList.filter(
                  (item) => !emails.includes(item.group)
                );
              });
            } else if (tab === tabIndexes.byPartnerTab) {
              setAllocationsItemListPartner((allocationsList) => {
                return allocationsList.filter(
                  (item) => !emails.includes(item.group)
                );
              });
            } else {
              setAllocationsItemList((allocationsList) => {
                return allocationsList.filter(
                  (item) => !emails.includes(item.group)
                );
              });
            }

            setIsLoading(false);

            setAllocations(
              allocationDetails,
              allocationTypes,
              tabIndexes,
              tab,
              timelineTimeZoneObj
            );
            callbackFn && callbackFn();
          },
          () => {
            setMessageBar(
              "An Error occurred in loading allocations",
              true,
              "error"
            );
            callbackFn && callbackFn();
          }
        );
      }
    },
    [allocationTypes, tabIndexes, tab, timelineTimeZoneObj]
  );

  const setAllocations = (
    allocationDetails,
    allocationTypes,
    tabIndexes,
    tab,
    timelineTimeZoneObj
  ) => {
    setIsLoading(true);

    let randomSeed = Math.floor(Math.random() * 1000);
    let allocations = [];
    let item;
    let i = 0;
    let textColor = "#000000";
    let bgColor = "#0000FF"; // Set default color as blue. Check the current implementation
    let selectedBgColor = "#808080";
    let allocationTypeObj;
    let tooltip = "",
      tooltipExtra = "";

    allocationDetails.forEach((allocation) => {
      allocationTypeObj = allocationTypes.find(
        (type) => type.id === allocation.allocationType
      );

      if (allocationTypeObj) {
        bgColor = allocationTypeObj.color;
      }
      if (
        allocation.isRecurring === "Yes" &&
        allocation.startTime &&
        allocation.endTime
      ) {
        let loopDate = allocation.startDate;
        let newDate,
          startTime,
          endTime,
          id,
          startTimeForTimezone,
          endTimeForTimezone,
          currentDate;

        while (loopDate <= allocation.endDate) {
          currentDate = new Date(loopDate.getTime());

          startTime = new Date(
            currentDate.setHours(
              allocation.startTime.split(":")[0],
              allocation.startTime.split(":")[1],
              0
            )
          );
          endTime = new Date(
            currentDate.setHours(
              allocation.endTime.split(":")[0],
              allocation.endTime.split(":")[1],
              0
            )
          );
          id =
            allocation.email +
            "_" +
            (allocation.id ? allocation.id : Math.random() * 1000) +
            "_" +
            new Date().getTime() +
            Math.random() * 1000;

          startTimeForTimezone = getTimeByTimezone(
            startTime,
            allocation.timeZoneObj,
            timelineTimeZoneObj
          );
          endTimeForTimezone = getTimeByTimezone(
            endTime,
            allocation.timeZoneObj,
            timelineTimeZoneObj
          );
          tooltip = [
            `Type: ${allocation.allocationTypeName ? allocation.allocationTypeName : allocationTypeObj ? allocationTypeObj.allocationTypeName : ""}`,
            `Eng. Code: ${allocation.engagementCode}`,
            `Start Date: ${formatDateWithDashes(allocation.startDate)}`,
            `End Date: ${formatDateWithDashes(allocation.endDate)}`,
          ].join("\n");

          tooltipExtra = [
            `Daily Start Time: ${getTimeFromDate(startTimeForTimezone)} (${timelineTimeZoneObj && getTimeZoneDisp(timelineTimeZoneObj.tzCode)})`,
            `Daily End Time: ${getTimeFromDate(endTimeForTimezone)} (${timelineTimeZoneObj && getTimeZoneDisp(timelineTimeZoneObj.tzCode)})`,
          ].join("\n");

          item = {
            id: id,
            group: allocation.email,
            title: allocation.allocationTypeName
              ? allocation.allocationTypeName
              : allocationTypeObj
                ? allocationTypeObj.allocationTypeName
                : "",
            tooltip: tooltip,
            tooltipExtra: tooltipExtra,
            start_time: startTimeForTimezone.getTime(),
            end_time: endTimeForTimezone.getTime(),
            isHourly: true,
            timeZoneObj: timelineTimeZoneObj,
            bgColor: bgColor,
            selectedBgColor: selectedBgColor,
            color: textColor,
            isRemovable: false,
            onRemoveItem: () => {
              onRemoveTimelineItem(allocation, id);
            },
          };

          allocations.push(item);

          newDate = loopDate.setDate(loopDate.getDate() + 1);
          loopDate = new Date(newDate);
        }
      } else {
        let id =
          allocation.email +
          "_" +
          (allocation.id ? allocation.id : Math.random() * 1000) +
          "_" +
          new Date().getTime() +
          Math.random() * 1000;
        let endDateTime = getEndDateTime(
          new Date(allocation.endDate.getTime())
        );
        tooltip = [
          `Type: ${allocation.allocationTypeName ? allocation.allocationTypeName : allocationTypeObj ? allocationTypeObj.allocationTypeName : ""}`,
          `Eng. Code: ${allocation.engagementCode}`,
          `Start Date: ${formatDateWithDashes(allocation.startDate)}`,
          `End Date: ${formatDateWithDashes(allocation.endDate)}`,
        ].join("\n");

        item = {
          id: id,
          group: allocation.email,
          title: allocation.allocationTypeName
            ? allocation.allocationTypeName
            : allocationTypeObj
              ? allocationTypeObj.allocationTypeName
              : "",
          tooltip: tooltip,
          start_time: allocation.startDate.getTime(),
          end_time: endDateTime,
          bgColor: bgColor,
          selectedBgColor: selectedBgColor,
          color: textColor,
          isRemovable:
            isTeamAllocPage &&
            isEngineeringAlloc(allocation.engagementId) &&
            !isPastDateForDefAlloc(allocation.startDate),
          onRemoveItem: () => {
            onRemoveTimelineItem(allocation, id);
          },
        };

        allocations.push(item);
      }

      i++;
    });

    if (tab === tabIndexes.byTeamTab) {
      setAllocationsItemListTeam((allocationsList) => {
        return [...allocationsList, ...allocations];
      });
    } else if (tab === tabIndexes.byPartnerTab) {
      setAllocationsItemListPartner((allocationsList) => {
        return [...allocationsList, ...allocations];
      });
    } else {
      setAllocationsItemList((allocationsList) => {
        return [...allocationsList, ...allocations];
      });
    }

    setIsLoading(false);
  };

  const onRemoveTimelineItem = (allocation, uniqueId) => {
    if (isEngineeringAlloc(allocation.engagementId)) {
      let confirmDialogDetails =
        ENGAGEMENTS.ALLOCATIONS.MESSAGES.DELETE_ALLOCATION;

      dispatchForm({
        type: ACTIONS.DELETE_ALLOCATION,
        confirmDialogDetails: {
          open: true,
          id: confirmDialogDetails.id,
          title: confirmDialogDetails.title,
          message: confirmDialogDetails.message,
          data: {
            allocationId: allocation.id,
            uniqueId: uniqueId,
          },
        },
      });
    } else {
      setMessageBar(
        "Please use engagements section of this application to delete this allocation",
        true,
        "info"
      );
    }
  };

  const submitAllocation = useCallback(async () => {
    const endPointUrl = AppConfig.baseUrl + AppConfig.bulkUploadAllocations;

    let allocationData = {
      email: selectedAllocationData.email,
      allocationType: selectedAllocationData.allocationTypeObj
        ? selectedAllocationData.allocationTypeObj.id
        : "",
      startDate:
        selectedAllocationData.startDate &&
        formatDateWithDashes(selectedAllocationData.startDate),
      endDate:
        selectedAllocationData.endDate &&
        formatDateWithDashes(selectedAllocationData.endDate),
      allocationStatus: selectedAllocationData.allocationStatus
        ? selectedAllocationData.allocationStatus
        : DefaultAllocationStatus,
      comment: selectedAllocationData.comment,
      engagementCode: selectedAllocationData.engagementCode,
      isTravelRequired: selectedAllocationData.isTravelRequired
        ? selectedAllocationData.isTravelRequired
        : "No",

      // isRecurring: isHourlyBasis ? "Yes" : "No",
      // startTime: isHourlyBasis && (selectedAllocationData.startTime !== null) ? getTimeFromDate(selectedAllocationData.startTime) : null,
      // endTime: isHourlyBasis && (selectedAllocationData.endTime !== null) ? getTimeFromDate(selectedAllocationData.endTime) : null,

      isRecurring: "No",
      startTime: null,
      endTime: null,
      timeZone: null,

      status: selectedAllocationData.status
        ? selectedAllocationData.status
        : "Active",
      engagementId: isTeamAllocPage
        ? "-1"
        : selectedAllocationData.engagementId,
      clearanceStatus: selectedAllocationData.clearanceStatus
        ? selectedAllocationData.clearanceStatus
        : "Confirmed",
      sfAllocationId: selectedAllocationData.sfAllocationId
        ? selectedAllocationData.sfAllocationId
        : "",
      roleConsultant: selectedAllocationData.roleConsultant
        ? selectedAllocationData.roleConsultant
        : "",
    };

    handleRequest(
      endPointUrl,
      "POST",
      { newAllocations: [allocationData] },
      (resData) => {
        setMessageBar("Allocation added successfully", true, "success");

        getAllocationsByEmail(
          [allocationData.email],
          timelineStartDate,
          timelineEndDate
        );
      },
      (customErrMsg) => {
        setMessageBar(
          customErrMsg ? customErrMsg : "An error occurred in allocation",
          true,
          "error"
        );
      },
      setIsLoading
    );
  }, [selectedAllocationData, isHourlyBasis, allocationTypes, tabIndexes, tab]);

  const getOverlaps = (defaultAllocations, startDate, endDate) => {
    // check for all overlaps up-front and get the confirmation, once confirmed execute operations
    let overlappedObjs = [];

    defaultAllocations.forEach((allocation) => {
      if (
        !(startDate < allocation.startDate && endDate < allocation.startDate) &&
        !(startDate > allocation.endDate)
      ) {
        overlappedObjs.push(allocation);
      }
    });

    return overlappedObjs;
  };

  const getAllocationObjectsForOverlapping = (
    overlappedObjs,
    startDate,
    endDate
  ) => {
    let itemStartDate, itemEndDate;
    let updateAllocObjs = [];
    let newAllocObjs = [];
    let newItem, updatedItem, startDateCopy, endDateCopy;
    let isPermitted = true;

    for (let allocation of overlappedObjs) {
      // overlappedObjs.forEach(allocation => {
      itemStartDate = allocation.startDate;
      itemEndDate = allocation.endDate;

      startDateCopy = new Date(startDate.getTime());
      endDateCopy = new Date(endDate.getTime());

      if (startDate <= itemStartDate && endDate >= itemEndDate) {
        // The new item overlaps the total period of the existing item. the existing item needs to be deleted.
        //            |--------------New Item--------------|
        //                  |---Overlapping Item---|

        setMessageBar(
          "Operation is not permitted! New allocation is fully overlaying an existing default allocation. Update / delete default allocation from Team Allocations page.",
          true,
          "error"
        );
        isPermitted = false;
        break;
      } else if (itemStartDate < startDate && endDate < itemEndDate) {
        // If new item is in middle of the overlapping item
        //            |---New Item---|
        //      |--- Overlapping Item ---|

        // Here we split overlapping item as below
        //      |--UD-|              |-N-|
        // UD - Update
        // N - New

        // Update object for |--UD-|
        updatedItem = getUpdateAllocationObj(
          allocation,
          allocation.startDate,
          new Date(startDateCopy.setDate(startDateCopy.getDate() - 1))
        );

        // Creating add object for |-N-|
        newItem = getNewAllocationObj(
          allocation,
          new Date(endDateCopy.setDate(endDateCopy.getDate() + 1)),
          allocation.endDate,
          null,
          null
        );

        updateAllocObjs.push(updatedItem);
        newAllocObjs.push(newItem);
      } else if (itemStartDate >= startDate) {
        //  |---New Item---|
        //      |--- Overlapping Item ---|
        //
        //      OR
        //
        //The overlapped item, new item starts in the same day. hence needs to update the overlapped items start date.
        //  |---New Item---|
        //  |--- Overlapping Item ---|

        // Update object
        updatedItem = getUpdateAllocationObj(
          allocation,
          new Date(endDateCopy.setDate(endDateCopy.getDate() + 1)),
          allocation.endDate
        );
        updateAllocObjs.push(updatedItem);
      } else if (itemStartDate < startDate) {
        // The overlapped item starts before the new item. Hence needs to update the item end date.
        //                          |---New Item---|
        //      |--- Overlapping Item ---|

        // Update object
        updatedItem = getUpdateAllocationObj(
          allocation,
          allocation.startDate,
          new Date(startDateCopy.setDate(startDateCopy.getDate() - 1))
        );
        updateAllocObjs.push(updatedItem);
      }
      // });
    }

    return {
      isPermitted: isPermitted,
      updateAllocObjs: updateAllocObjs,
      newAllocObjs: newAllocObjs,
    };
  };

  const getNewAllocationObj = (
    data,
    newStartDate,
    newEndDate,
    status,
    isHourly
  ) => {
    let countryItem = countries.find(
      (countryItem) => countryItem[1] === data.country
    );

    return {
      email: data.email,
      allocationType: data.allocationTypeObj ? data.allocationTypeObj.id : "",
      startDate: formatDateWithDashes(
        newStartDate ? newStartDate : data.startDate
      ),
      endDate: formatDateWithDashes(newEndDate ? newEndDate : data.endDate),
      engagementId: data.engagementId ? data.engagementId : null,
      clearanceStatus: data.isTentativeAllocation
        ? AllocationStatus.TENTATIVE
        : status
          ? status
          : data.clearanceStatus
            ? data.clearanceStatus
            : AllocationStatus.CONFIRMED,
      comment: data.comment ? data.comment : "",
      isTravelRequired: data.isTravelRequired === "Yes" ? "Yes" : "No",
      engagementCode: data.engagementCode ? data.engagementCode : "",

      isRecurring: isHourly ? "Yes" : "No",
      startTime:
        isHourly && data.startTime !== null
          ? getTimeFromDate(data.startTime)
          : null,
      endTime:
        isHourly && data.endTime !== null
          ? getTimeFromDate(data.endTime)
          : null,
      timeZone:
        isHourly && data.timeZoneObj !== null && data.timeZoneObj !== undefined
          ? data.timeZoneObj.tzCode
          : null,

      allocationStatus: data.allocationStatus
        ? data.allocationStatus
        : DefaultAllocationStatus,
      status: data.status ? data.status : "Active",
      countryCode: countryItem && countryItem[0],
    };
  };

  const getUpdateAllocationObj = (data, newStartDate, newEndDate) => {
    return {
      id: data.id,
      // email: data.email,
      // allocationType: data.allocationTypeObj ? data.allocationTypeObj.id : "",
      startDate: formatDateWithDashes(
        newStartDate ? newStartDate : data.startDate
      ),
      endDate: formatDateWithDashes(newEndDate ? newEndDate : data.endDate),
      // engagementId: data.engagementId ? data.engagementId : null,
      // engagementCode: data.engagementCode ? data.engagementCode : "",
      // clearanceStatus: status ? status : (data.clearanceStatus ? data.clearanceStatus : "Confirmed"),
      // roleConsultant: data.roleConsultant ? data.roleConsultant : null,
      // isTravelRequired: data.isTravelRequired === "Yes" ? "Yes" : "No",
      // comment: data.comment ? data.comment : null,

      // isRecurring: isHourlyBasis ? "Yes" : "No",
      // startTime: isHourlyBasis && (data.startTime !== null) ? getTimeFromDate(data.startTime) : null,
      // endTime: isHourlyBasis && (data.endTime !== null) ? getTimeFromDate(data.endTime) : null,
      // timeZone: isHourlyBasis && (data.timeZoneObj !== null && data.timeZoneObj !== undefined) ? data.timeZoneObj.tzCode : null,

      // allocationStatus: data.allocationStatus ? data.allocationStatus : DefaultAllocationStatus,
      // status: "Active",
      // sfAllocationId: data.sfAllocationId ? data.sfAllocationId : "",
    };
  };

  const getAllocationsByEngagementId = useCallback(
    async (limit, offset, searchString, sortByObj) => {
      const endPointUrl =
        AppConfig.baseUrl +
        AppConfig.getAllocationsByEngagementId.replace("$id", engagementId);

      const body = {
        limit: limit ?? tablePageSize,
        offset: offset ?? DEFAULT_TABLE_OFFSET,
        searchString: searchString ?? filterText,
        searchColumns: [
          "id",
          "email",
          "startDate",
          "endDate",
          "clearanceStatus",
          "comment",
          "startTime",
          "endTime",
          "timeZone",
        ],
        sortColumn: sortByObj
          ? ALLOCATION_TABLE_COLUMN_FIELD_MAP[sortByObj.id]
          : sortBy
            ? ALLOCATION_TABLE_COLUMN_FIELD_MAP[sortBy.id]
            : null,
        sortOrder: sortByObj
          ? sortByObj.desc
            ? SORT_ORDER.DESC
            : SORT_ORDER.ASC
          : sortBy
            ? sortBy.desc
              ? SORT_ORDER.DESC
              : SORT_ORDER.ASC
            : null,
      };

      handleRequest(
        endPointUrl,
        "POST",
        body,
        (allocations) => {
          let formattedAllocationsList = [];
          let formattedAllocation;

          allocations.forEach((allocation) => {
            formattedAllocation = {
              id: allocation.id,
              email: allocation.email,
              allocationType: allocation.allocationType,
              allocationTypeObj:
                allocationTypes &&
                allocationTypes.find(
                  (type) => type.id === allocation.allocationType
                ),
              startDate:
                allocation.startDate &&
                getDateObjectFromString(
                  getDateFromString(allocation.startDate)
                ),
              endDate:
                allocation.endDate &&
                getDateObjectFromString(getDateFromString(allocation.endDate)),
              comment: allocation.comment,
              engagementCode: allocation.engagement?.engagementCode,
              isRecurring: allocation.isRecurring,
              startTime:
                allocation.startTime &&
                getDateObjectFromTimeString(allocation.startTime),
              endTime:
                allocation.endTime &&
                getDateObjectFromTimeString(allocation.endTime),
              timeZoneObj:
                allocation.timeZone && getTimeZoneObj(allocation.timeZone),
              startEndTime:
                allocation.startTime && allocation.endTime
                  ? getTimeWithoutSecs(allocation.startTime) +
                    " - " +
                    getTimeWithoutSecs(allocation.endTime) +
                    (allocation.timeZone
                      ? " (" + getTimeZoneDisp(allocation.timeZone) + ")"
                      : "")
                  : "N/A",
              engagementId: allocation.engagementId,
              allocationStatus: allocation.allocationStatus,
              clearanceStatus: allocation.clearanceStatus,
              isTravelRequired: allocation.isTravelRequired,

              engagementTypeId: allocation.type,
              address: allocation.address,
              customerName: allocation.customerName,
              productId: allocation.productId,
              opportunity: allocation.opportunityName,
              productName: allocation.productName,
              engagementNature: allocation.engagementNature,
              country: allocation.country,
              engagementNature: allocation.engagementNature,
              customerContact: allocation.customerContact,
              businessDomain: allocation.businessDomain,
              opportunityOwner: allocation.opportunityOwner,
              technicalOwner: allocation.technicalOwner,
              isContractsSigned: allocation.isContractsSigned,
              engagementScope: allocation.engagementScope,
              role: "",
              engagementComment: allocation.engagementComment,

              startDateDisp: getDateFromString(allocation.startDate),
              endDateDisp: getDateFromString(allocation.endDate),
            };

            formattedAllocationsList.push(formattedAllocation);
          });

          setAddedAllocationsList(formattedAllocationsList);
        },
        () => {},
        setIsLoading
      );
    },
    [allocationTypes, tablePageSize, engagementId]
  );

  const getDataFromAllocationUniqueId = (id) => {
    return {
      email: id.split("_")[0],
      allocationId: id.split("_")[1],
    };
  };

  const onAllocationItemDoubleClick = (itemId) => {
    if (isTeamAllocPage) {
      let dataFromUniqueId = getDataFromAllocationUniqueId(itemId);

      let email = dataFromUniqueId.email;
      let allocationId = dataFromUniqueId.allocationId;

      if (email) {
        let allocationsPerEmail = emailAllocationDetailsMap[email];

        if (allocationsPerEmail) {
          let allocationObj = allocationsPerEmail.find(
            (allocation) => allocation.id === allocationId
          );

          if (allocationObj) {
            // If the allocation is not for an engagement allocation, then we allow it to be updated
            if (isEngineeringAlloc(allocationObj.engagementId)) {
              // Enable Update Allocation
              openUpdateAllocationStatusPopup(allocationObj, true);
            } else {
              setMessageBar(
                "This allocation is either Paid, Non-Paid Post Sale or Non-Paid Pre Sale engagement type of allocation. Please use Engagements section to update this engagement allocation",
                true,
                "error"
              );
            }

            // openUpdateAllocationStatusPopup(allocationObj);
          }
        }
      }
    }
  };

  const updateAllocation = useCallback(async () => {
    const endPointUrl =
      AppConfig.baseUrl +
      AppConfig.updateAllocation.replace("$id", selectedAllocationData.id);
    let status = "Active";

    if (
      [
        AllocationStatus.ALLOCATION_CANCELLED,
        AllocationStatus.REJECTED_BY_CONSULTANT,
        AllocationStatus.REJECTED_VISA_ISSUES,
        AllocationStatus.REJECTED_OTHER,
      ].includes(selectedAllocationData.clearanceStatus)
    ) {
      status = "Inactive";
    }

    let countryItem = countries.find(
      (countryItem) => countryItem[1] === selectedAllocationData.country
    );

    let allocationData = {
      id: selectedAllocationData.id,
      clearanceStatus: selectedAllocationData.clearanceStatus,
      comment: selectedAllocationData.comment,
      status: status,

      countryCode: countryItem && countryItem[0],
      prevClearanceStatus: prevClearanceStatus,
    };

    handleRequest(
      endPointUrl,
      "PATCH",
      allocationData,
      (data) => {
        setMessageBar("Allocation updated successfully", true, "success");
        let newAddedAllocationsList = [...addedAllocationsList];
        let updateItemIndex = newAddedAllocationsList.findIndex(
          (element) => element.id === selectedAllocationData.id
        );

        if (updateItemIndex !== -1) {
          newAddedAllocationsList[updateItemIndex]["clearanceStatus"] =
            selectedAllocationData.clearanceStatus;
          // newAddedAllocationsList[updateItemIndex]["allocationStatus"] = selectedAllocationData.clearanceStatus;
          newAddedAllocationsList[updateItemIndex]["comment"] =
            selectedAllocationData.comment;

          setAddedAllocationsList(newAddedAllocationsList);
        }
      },
      (customErrMsg) => {
        setMessageBar(
          customErrMsg ? customErrMsg : "Error occurred in allocation update",
          true,
          "error"
        );
      },
      setIsLoading
    );
  }, [selectedAllocationData, allocationTypes, addedAllocationsList]);

  const updateTeamAllocation = useCallback(async () => {
    const endPointUrl =
      AppConfig.baseUrl +
      AppConfig.updateAllocation.replace("$id", selectedAllocationData.id);

    let allocationData = {
      allocationType: selectedAllocationData.allocationTypeObj
        ? selectedAllocationData.allocationTypeObj.id
        : selectedAllocationData.allocationType,
      engagementCode: selectedAllocationData.engagementCode,
      startDate: formatDateWithDashes(selectedAllocationData.startDate),
      endDate: formatDateWithDashes(selectedAllocationData.endDate),
      comment: selectedAllocationData.comment,
    };

    handleRequest(
      endPointUrl,
      "PATCH",
      allocationData,
      (resData) => {
        getAllocationsByEmail(
          [selectedAllocationData.email],
          timelineStartDate,
          timelineEndDate
        );

        setMessageBar("Allocation updated successfully", true, "success");
      },
      (customErrMsg) => {
        setMessageBar(
          customErrMsg
            ? customErrMsg
            : "An error occurred in allocation update",
          true,
          "error"
        );
      },
      setIsLoading
    );
  }, [selectedAllocationData, allocationTypes]);

  const openAddNewAllocationPopup = (email) => {
    dispatchForm({
      type: ACTIONS.ADD_NEW_ALLOCATION,
      allocationsPopupDetails: {
        open: true,
      },
    });

    let allocStartDate = isTeamAllocPage ? timelineStartDate : data.startDate;
    let allocEndDate = isTeamAllocPage ? timelineEndDate : data.endDate;

    if (isInternalEngagement) {
      setStatusList([
        AllocationStatus.TENTATIVE,
        AllocationStatus.CLEARANCE_IN_PROGRESS,
        AllocationStatus.CONFIRMED,
        AllocationStatus.CONFIRMED_VISA_PENDING,
      ]);
    }

    setSelectedAllocationData({
      ...initSelectedAllocationData,
      ...data,
      email: email ? email : selectedEmail.id,
      isTravelRequired: "",
      comment: "",
      timeZoneObj: timelineTimeZoneObj,
      startDate: isTeamAllocPage
        ? isPastDateForDefAlloc(allocStartDate)
          ? getAllowedPastDateForDefAlloc()
          : allocStartDate
        : isPastDateForAlloc(allocStartDate)
          ? getAllowedDefaultDate()
          : allocStartDate,
      endDate: isTeamAllocPage
        ? isPastDateForDefAlloc(allocEndDate)
          ? getAllowedPastDateForDefAlloc()
          : allocEndDate
        : isPastDateForAlloc(allocEndDate)
          ? getAllowedDefaultDate()
          : allocEndDate,

      isPreparationOverhead: false,
      isPostOverhead: false,
      preparationStartDate: isPaidEngagement(data.engagementTypeId)
        ? isPastDateForAlloc(data.startDate)
          ? getAllowedDefaultDate()
          : data.startDate
        : null,
      postEndDate: isPaidEngagement(data.engagementTypeId)
        ? isPastDateForAlloc(data.endDate)
          ? getAllowedDefaultDate()
          : data.endDate
        : null,
      // clearanceStatus: isInternalEngagement ? AllocationStatus.CONFIRMED : null
    });
  };

  const openUpdateAllocationStatusPopup = (data, isTeamAlloc) => {
    dispatchForm({
      type: ACTIONS.UPDATE_ALLOCATION_STATUS,
      allocationsPopupDetails: {
        open: true,
      },
    });

    if (!isTeamAlloc) {
      let currentClearanceStatus = data.clearanceStatus;

      setPrevClearanceStatus(currentClearanceStatus);

      setSelectedAllocationData({
        ...initSelectedAllocationData,
        ...data,
        clearanceStatus: getStatusList(currentClearanceStatus)[0],
      });

      setStatusList(getStatusList(currentClearanceStatus));
      setIsHourlyBasis(data.isRecurring === "Yes");
    } else {
      setSelectedAllocationData({
        ...initSelectedAllocationData,
        ...data,
        minStartDate: isPastDateForDefAlloc(data.startDate)
          ? null
          : getAllowedPastDateForDefAlloc(),
        minEndDate: isPastDateForDefAlloc(data.endDate)
          ? null
          : getAllowedPastDateForDefAlloc(),
        initStartDate: new Date(data.startDate.getTime()),
        initEndDate: new Date(data.endDate.getTime()),
      });
    }
  };

  const handleAllocationData = (property, value) => {
    setSelectedAllocationData((prevAllocationData) => {
      return {
        ...prevAllocationData,
        [property]: value,
      };
    });
  };

  const handleAllocationsSubmission = useCallback(
    (
      isConfirm,
      isAllocationStatusEdit,
      email,
      isPreparationOverhead,
      isPostOverhead
    ) => {
      if (isConfirm) {
        setIsSubmitBtnClicked(true);

        let isValidData = isAllocationFormValid(
          selectedAllocationData,
          isPreparationOverhead,
          isPostOverhead
        );

        if (isValidData.isValid) {
          if (isTeamAllocPage) {
            let allocations =
              emailAllocationDetailsMap[email ? email : selectedEmail.id];

            _validateAndSubmitAllocations(
              allocations,
              isAllocationStatusEdit,
              false,
              false,
              ""
            );
          } else {
            getAllocations(
              [email ? email : selectedEmail.id],
              originalTimelineStartDate,
              originalTimelineEndDate,
              [],
              (allocationDetails) => {
                _validateAndSubmitAllocations(
                  allocationDetails,
                  isAllocationStatusEdit,
                  isPreparationOverhead,
                  isPostOverhead,
                  "Please refresh the timeline if you do not have the latest / cannot see it in the timeline."
                );
              },
              () => {
                setMessageBar(
                  "An error occurred in pre-validation. Please retry.",
                  true,
                  "error"
                );
              }
            );
          }
        } else {
          setMessageBar(isValidData.msg, true, "error");
        }
      } else {
        // Closes the New Allocations Popup
        dispatchForm({
          type: isAllocationStatusEdit
            ? ACTIONS.UPDATE_ALLOCATION_STATUS
            : ACTIONS.ADD_NEW_ALLOCATION,
          allocationsPopupDetails: {},
        });

        resetForm();
      }
    },
    [emailAllocationDetailsMap, selectedAllocationData, isHourlyBasis]
  );

  const getAllocations = useCallback(
    async (emailsArr, startDate, endDate, allocTypesArr, successFn, failFn) => {
      const endPointUrl = AppConfig.baseUrl + AppConfig.getAllocationsByEmail;

      const body = {
        emails: emailsArr,
        startDate: formatDateWithDashes(startDate),
        endDate: formatDateWithDashes(endDate),
        allocTypes: allocTypesArr,
      };

      handleRequest(
        endPointUrl,
        "POST",
        body,
        (data) => {
          let allocationDetails = [];
          let allocationDetailsMap = {};
          let allocation;

          data.forEach((item) => {
            if (!allocationDetailsMap[item.email]) {
              allocationDetailsMap[item.email] = [];
            }

            allocation = {
              ...item,
              startDate: getDateObjectFromString(item.startDate),
              endDate: getDateObjectFromString(item.endDate),
              timeZoneObj: getTimeZoneObj(item.timeZone),
              allocationTypeObj:
                allocationTypes &&
                allocationTypes.find((type) => type.id === item.allocationType),
            };

            allocationDetailsMap[item.email].push(allocation);
            allocationDetails.push(allocation);
          });

          if (successFn) {
            successFn(allocationDetails, allocationDetailsMap);
          }
        },
        () => {
          if (failFn) {
            failFn();
          }
        },
        setIsGetAllocationsInProgress
      );
    },
    []
  );

  const _validateAndSubmitAllocations = (
    allocations,
    isAllocationStatusEdit,
    isPreparationOverhead,
    isPostOverhead,
    additionalMsg
  ) => {
    let defaultAllocations = allocations
      ? allocations.filter((allocation) =>
          isEngineeringAlloc(allocation.engagementId)
        )
      : [];
    let engagementAllocations = allocations
      ? allocations.filter(
          (allocation) => !isEngineeringAlloc(allocation.engagementId)
        )
      : [];

    let msg = "";
    let allocationsToCompare = allocations;

    if (isTeamAllocPage) {
      if (isAllocationStatusEdit) {
        // Remove current allocation from the list
        allocationsToCompare = allocations.filter(
          (allocation) => allocation.id !== selectedAllocationData.id
        );
      }
    } else {
      if (isAllocationStatusEdit) {
        // Remove current allocation from the engagement allocations list
        allocationsToCompare = engagementAllocations.filter(
          (allocation) => allocation.id !== selectedAllocationData.id
        );
      } else {
        // Engagement allocations list
        allocationsToCompare = engagementAllocations;
      }
    }

    let prepOverheadEndDate = null;
    let postOverheadStartDate = null;

    if (isPreparationOverhead) {
      prepOverheadEndDate = new Date(
        selectedAllocationData.startDate.getTime()
      );
      prepOverheadEndDate.setDate(prepOverheadEndDate.getDate() - 1);
    }

    if (isPostOverhead) {
      postOverheadStartDate = new Date(
        selectedAllocationData.endDate.getTime()
      );
      postOverheadStartDate.setDate(postOverheadStartDate.getDate() + 1);
    }

    if (
      !isPersonAvailable(
        allocationsToCompare,
        isTeamAllocPage ? null : data.startDate,
        isTeamAllocPage ? null : data.endDate,
        selectedAllocationData.startDate,
        selectedAllocationData.endDate,
        !isTeamAllocPage,
        !isTeamAllocPage,
        selectedAllocationData.startTime,
        selectedAllocationData.endTime,
        selectedAllocationData.timeZoneObj
      )
    ) {
      msg =
        "This person is already allocated for the selected period of time." +
        additionalMsg;
    } else if (
      isPreparationOverhead &&
      !isPersonAvailable(
        allocationsToCompare,
        data.startDate,
        data.endDate,
        selectedAllocationData.preparationStartDate,
        prepOverheadEndDate,
        false
      )
    ) {
      msg =
        "This person is already allocated for the selected period of preparation overhead." +
        additionalMsg;
    } else if (
      isPostOverhead &&
      !isPersonAvailable(
        allocationsToCompare,
        data.startDate,
        data.endDate,
        postOverheadStartDate,
        selectedAllocationData.postEndDate,
        false
      )
    ) {
      msg =
        "This person is already allocated for the selected period of post overhead." +
        additionalMsg;
    } else {
      if (isTeamAllocPage) {
        handleRequest(
          AppConfig.baseUrl + AppConfig.validateAllocation,
          "POST",
          {
            startDate:
              selectedAllocationData.startDate &&
              formatDateWithDashes(selectedAllocationData.startDate),
            endDate:
              selectedAllocationData.endDate &&
              formatDateWithDashes(selectedAllocationData.endDate),
            emails: [selectedAllocationData.email],
          },
          (resData) => {
            // This is to filter out the own record when updating allocation
            let filteredData = resData.filter(
              (data) => data.id !== selectedAllocationData.id
            );
            if (filteredData.length === 0) {
              if (isAllocationStatusEdit) {
                updateTeamAllocation();
              } else {
                submitAllocation();
              }

              // Closes the New Allocations Popup
              dispatchForm({
                type: isAllocationStatusEdit
                  ? ACTIONS.UPDATE_ALLOCATION_STATUS
                  : ACTIONS.ADD_NEW_ALLOCATION,
                allocationsPopupDetails: {},
              });

              resetForm();
            } else {
              let confirmDialogDetails =
                ENGAGEMENTS.ALLOCATIONS.MESSAGES.UPDATE_TIMELINE_ON_OVERLAP;

              dispatchForm({
                type: ACTIONS.UPDATE_TIMELINE_ON_OVERLAP,
                confirmDialogDetails: {
                  open: true,
                  id: confirmDialogDetails.id,
                  title: confirmDialogDetails.title,
                  message: confirmDialogDetails.message,
                },
              });

              // setMessageBar("This allocation overlaps with existing allocations. Please check existing allocations before submitting.", true, 'error');
            }
          },
          () => {
            setMessageBar(
              "An error occurred in validating the allocation",
              true,
              "error"
            );
          },
          setIsLoading
        );
      } else {
        // submitAllAllocations(isPreparationOverhead, isPostOverhead);

        let startDate = selectedAllocationData.startDate;
        let endDate = selectedAllocationData.endDate;

        if (isPreparationOverhead) {
          startDate = selectedAllocationData.preparationStartDate;
        }

        if (isPostOverhead) {
          endDate = selectedAllocationData.postEndDate;
        }

        let overlappedObjs = getOverlaps(
          defaultAllocations,
          startDate,
          endDate
        );
        // let overlappedObjs = getOverlaps(defaultAllocations, newAllocation.startDate, newAllocation.endDate);
        // let overlappedObjsPrepOverhead = [];
        // let overlappedObjsPostOverhead = [];

        // if (isPreparationOverhead) {
        //     overlappedObjsPrepOverhead = getOverlaps(defaultAllocations, newAllocation.preparationStartDate, newAllocation.startDate - 1);
        //     // Remove duplicates in insert
        //     overlappedObjs.push(overlappedObjsPrepOverhead);
        // }

        // if (isPostOverhead) {
        //     overlappedObjsPostOverhead = getOverlaps(defaultAllocations, newAllocation.endDate + 1, newAllocation.postEndDate);
        //     // Remove duplicates in insert
        //     overlappedObjs.push(overlappedObjsPostOverhead);
        // }

        if (overlappedObjs.length > 0) {
          // This is actually not needed when isAllocationStatusEdit is true, since we don't allow date changes on update. But this is kept as it is, since implemented already.
          onAllocationOverlap(
            selectedAllocationData,
            isAllocationStatusEdit,
            isPreparationOverhead,
            isPostOverhead,
            overlappedObjs,
            startDate,
            endDate
          );
        } else {
          if (isAllocationStatusEdit) {
            updateAllocation();

            // Closes the New Allocations Popup
            dispatchForm({
              type: isAllocationStatusEdit
                ? ACTIONS.UPDATE_ALLOCATION_STATUS
                : ACTIONS.ADD_NEW_ALLOCATION,
              allocationsPopupDetails: {},
            });

            resetForm();
          } else {
            createAndSubmitAllAllocations(
              selectedAllocationData,
              isPreparationOverhead,
              isPostOverhead,
              false
            );
          }
        }
      }
    }

    if (msg) {
      setMessageBar(msg, true, "error");
    }
  };

  const onAllocationOverlap = (
    selectedAllocationData,
    isAllocationStatusEdit,
    isPreparationOverhead,
    isPostOverhead,
    overlappedObjs,
    startDate,
    endDate
  ) => {
    let confirmDialogDetails =
      ENGAGEMENTS.ALLOCATIONS.MESSAGES.ALLOCATION_WITH_OVERLAPS;

    dispatchForm({
      type: ACTIONS.ALLOCATION_WITH_OVERLAPS,
      confirmDialogDetails: {
        open: true,
        id: confirmDialogDetails.id,
        title: confirmDialogDetails.title,
        message: confirmDialogDetails.message,
        data: {
          selectedAllocationData: selectedAllocationData,
          isAllocationStatusEdit: isAllocationStatusEdit,
          overlappedObjs: overlappedObjs,
          startDate: startDate,
          endDate: endDate,
          isPreparationOverhead: isPreparationOverhead,
          isPostOverhead: isPostOverhead,
        },
      },
    });
  };

  const handleAllocateOnOVerlap = (isConfirm) => {
    if (isConfirm) {
      let isAllocationStatusEdit =
        confirmDialogDetails.data.isAllocationStatusEdit;
      let overlappedObjs = confirmDialogDetails.data.overlappedObjs;
      let startDate = confirmDialogDetails.data.startDate;
      let endDate = confirmDialogDetails.data.endDate;
      let isPreparationOverhead =
        confirmDialogDetails.data.isPreparationOverhead;
      let isPostOverhead = confirmDialogDetails.data.isPostOverhead;

      if (!isAllocationStatusEdit) {
        createAndSubmitAllAllocations(
          confirmDialogDetails.data.selectedAllocationData,
          isPreparationOverhead,
          isPostOverhead,
          true,
          startDate,
          endDate,
          overlappedObjs
        );
      }
    }

    dispatchForm({
      type: ACTIONS.ALLOCATION_WITH_OVERLAPS,
      confirmDialogDetails: {},
    });
  };

  const createAndSubmitAllAllocations = (
    selectedAllocationData,
    isPreparationOverhead,
    isPostOverhead,
    isOverlapped,
    startDate,
    endDate,
    overlappedObjs
  ) => {
    let newAllocations = [],
      prepOverheadAllocation;

    let clearanceStatus = "";

    if (
      selectedAllocationData.email.indexOf("partner-allocations-group") === -1
    ) {
      if (isInternalEngagement) {
        clearanceStatus = selectedAllocationData.clearanceStatus;
      } else {
        clearanceStatus = AllocationStatus.READY_FOR_CLEARANCE;
      }
    } else {
      clearanceStatus = AllocationStatus.CONFIRMED;
    }

    let allocStartDateObj = selectedAllocationData.startDate;
    let allocEndDateObj = selectedAllocationData.endDate;

    let newAllocation = getNewAllocationObj(
      selectedAllocationData,
      allocStartDateObj,
      allocEndDateObj,
      clearanceStatus,
      isHourlyBasis
    );

    newAllocations.push({
      ...newAllocation,
      prevClearanceStatus: prevClearanceStatus,
    });

    // Engagement overheads (EO) are allowed only for allocation types falling under Paid engagement type
    // This method's allocation type id is always equal to EO allocation type's allocation id
    // Therefore we can use its engagementCodeSuffix to extract the engagement code suffix of EO
    let eoAllocationTypeObj;

    if (isPreparationOverhead || isPostOverhead) {
      eoAllocationTypeObj = allocationTypes.find(
        (type) => type.allocationTypeName === EO_ALLOCATION_TYPE
      );
    }

    let prepOverheadEndDate = new Date(allocStartDateObj.getTime());
    prepOverheadEndDate.setDate(prepOverheadEndDate.getDate() - 1);

    if (isPreparationOverhead) {
      prepOverheadAllocation = getNewAllocationObj(
        {
          email: newAllocation.email,
          allocationTypeObj: eoAllocationTypeObj,
          roleConsultant: "",
          engagementId: "-1.0",
          engagementCode:
            newAllocation.engagementCode +
            "-" +
            eoAllocationTypeObj.engagementCodeSuffix,
        },
        selectedAllocationData.preparationStartDate,
        prepOverheadEndDate,
        null,
        false
      );

      newAllocations.push(prepOverheadAllocation);
    }

    let postOverheadStartDate = new Date(allocEndDateObj.getTime());
    postOverheadStartDate.setDate(postOverheadStartDate.getDate() + 1);

    if (isPostOverhead) {
      prepOverheadAllocation = getNewAllocationObj(
        {
          email: newAllocation.email,
          allocationTypeObj: eoAllocationTypeObj,
          roleConsultant: "",
          engagementId: "-1.0",
          engagementCode:
            newAllocation.engagementCode +
            "-" +
            eoAllocationTypeObj.engagementCodeSuffix,
        },
        postOverheadStartDate,
        selectedAllocationData.postEndDate,
        null,
        false
      );

      newAllocations.push(prepOverheadAllocation);
    }

    let updateAllocations = [];
    let isSubmissionPermitted = true;

    if (isOverlapped) {
      let dataForOverlapping = getAllocationObjectsForOverlapping(
        overlappedObjs,
        startDate,
        endDate
      );
      isSubmissionPermitted = dataForOverlapping.isPermitted;

      if (isSubmissionPermitted) {
        updateAllocations = dataForOverlapping.updateAllocObjs;
        newAllocations.push(...dataForOverlapping.newAllocObjs);
      }
    }

    if (!isOverlapped || (isOverlapped && isSubmissionPermitted)) {
      // Closes the New Allocations Popup
      dispatchForm({
        type: isAllocationStatusEdit
          ? ACTIONS.UPDATE_ALLOCATION_STATUS
          : ACTIONS.ADD_NEW_ALLOCATION,
        allocationsPopupDetails: {},
      });

      resetForm();

      // Handle newAllocations array submission
      let endPointUrl = AppConfig.baseUrl + AppConfig.bulkUploadAllocations;

      handleRequest(
        endPointUrl,
        "POST",
        {
          newAllocations: newAllocations,
          updateAllocations: updateAllocations,
        },
        (data) => {
          setMessageBar("Allocation added successfully", true, "success");

          let newAllocation = newAllocations[0];

          // getAllocationsByEmail([newAllocation.email], selectedAllocationData.preparationStartDate ? selectedAllocationData.preparationStartDate : allocStartDateObj, selectedAllocationData.postEndDate ? selectedAllocationData.postEndDate : allocEndDateObj);
          getAllocationsByEmail(
            [newAllocation.email],
            timelineStartDate,
            timelineEndDate
          );
          fetchDataOnSearch(); // Update the data in allocation records table

          if (requestStatus && requestStatus !== RequestStatus.IN_PROGRESS) {
            updateRequestStatus(
              selectedAllocationData.engagementId,
              RequestStatus.IN_PROGRESS
            );
          }
        },
        (customErrMsg) => {
          setMessageBar(
            customErrMsg
              ? customErrMsg
              : "Error occurred in adding new Allocation",
            true,
            "error"
          );
        },
        setIsLoading
      );
    }
  };

  const resetForm = () => {
    setIsHourlyBasis(false);
    setIsHourlyBasisDisabled(false);
    setIsSubmitBtnClicked(false);

    setSelectedAllocationData({
      ...initSelectedAllocationData,
      isPreparationOverhead: false,
      isPostOverhead: false,
      allocationTypeObj: null,
    });
  };

  // Email is passed as an argument in double click on timeline
  const onClickAddBtn = (email) => {
    if (
      !isTeamAllocPage &&
      isPastDateForAlloc(data.startDate) &&
      isPastDateForAlloc(data.endDate)
    ) {
      setMessageBar("You cannot allocate for past engagements", true, "error");
    } else if (email || selectedEmail) {
      if (isTeamAllocPage) {
        openAddNewAllocationPopup(email);
      } else {
        getAllocations(
          [email ? email : selectedEmail.id],
          data.startDate,
          data.endDate,
          [],
          (allocationDetails, allocationDetailsMap) => {
            let engagementAllocations = allocationDetails
              ? allocationDetails.filter(
                  (allocation) => !isEngineeringAlloc(allocation.engagementId)
                )
              : [];
            let isAvailable = isPersonAvailable(
              engagementAllocations,
              data.startDate,
              data.endDate,
              null,
              null,
              true,
              false,
              data.startTime,
              data.endTime,
              data.timeZoneObj
            );

            if (isAvailable) {
              // Open the Add New Allocation popup
              openAddNewAllocationPopup(email);
            } else {
              setMessageBar(
                "This person is already allocated for the selected period of time. Please refresh the timeline if you do not have the latest / cannot see it in the timeline.",
                true,
                "error"
              );
            }
          },
          () => {
            setMessageBar(
              "An error occurred in pre-validation. Please retry.",
              true,
              "error"
            );
          }
        );
      }
    } else {
      setMessageBar("Please select an email", true, "error");
    }
  };

  const isPersonAvailable = (
    allocations,
    engStartDate,
    engEndDate,
    newAllocStartDate,
    newAllocEndDate,
    isHourlyAllocationsAllowed,
    isValidatedHourly,
    engStartTime,
    engEndTime,
    engTimeZoneObj
  ) => {
    let isAvailable = true;

    let allocStartDate;
    let allocEndDate;
    let allocStartTime;
    let allocEndTime;

    if (allocations) {
      for (let allocation of allocations) {
        // if (!defaultAllocationTypes.includes(allocation.allocationType)): this cannot be used to check if an allocation is default (engineering) or not. There are engagement allocations with these alloc types
        // if (!isEngineeringAlloc(allocation.engagementId)) {
        allocStartDate = new Date(allocation.startDate);
        allocStartDate.setHours(0);
        allocStartDate.setMinutes(0);

        allocEndDate = new Date(allocation.endDate);
        allocEndDate.setHours(0);
        allocEndDate.setMinutes(0);

        // If engagement start / end dates and (existing) allocation start / end dates "fully" (since we allow partial allocations) overlaps, a new allocation can't be done.
        // But if the (existing) allocation is hourly based, a new hourly based allocation can be done
        if (
          (allocStartDate <= engStartDate && engEndDate <= allocEndDate) ||
          (allocStartDate <= newAllocStartDate &&
            newAllocStartDate <= allocEndDate) ||
          (allocStartDate >= newAllocStartDate &&
            allocStartDate <= newAllocEndDate)
        ) {
          if (allocation.isRecurring === "Yes" && isHourlyAllocationsAllowed) {
            // If new allocation is hourly based
            if (isHourlyBasis) {
              allocStartTime = new Date();
              allocStartTime.setHours(allocation.startTime.split(":")[0]);
              allocStartTime.setMinutes(allocation.startTime.split(":")[1]);
              allocStartTime.setSeconds(0);

              allocEndTime = new Date();
              allocEndTime.setHours(allocation.endTime.split(":")[0]);
              allocEndTime.setMinutes(allocation.endTime.split(":")[1]);
              allocStartTime.setSeconds(0);

              let timeZoneObj = allocation.timeZoneObj;
              let allocBaseStartTime = getBaseTime(allocStartTime, timeZoneObj);
              let allocBaseEndTime = getBaseTime(allocEndTime, timeZoneObj);
              let engBaseStartTime = getBaseTime(engStartTime, engTimeZoneObj);
              let engBaseEndTime = getBaseTime(engEndTime, engTimeZoneObj);

              if (
                (allocBaseStartTime <= engBaseStartTime &&
                  engBaseStartTime < allocBaseEndTime) ||
                (allocBaseStartTime >= engBaseStartTime &&
                  allocBaseStartTime <= engBaseEndTime)
              ) {
                isAvailable = false;
                break;
              }
              // } else {
              //     // Update popup to only allow hourly allocations
              //     setIsHourlyBasis(true);
              //     setIsHourlyBasisDisabled(true);
            } else if (isValidatedHourly) {
              // Hourly validation happens only in submission; On click add button, if the employee is already hourly allocated, we only allow hourly allocations
              isAvailable = false;
              break;
            } else {
              // Update popup to only allow hourly allocations
              setIsHourlyBasis(true);
              setIsHourlyBasisDisabled(true);
            }
          } else {
            isAvailable = false;
            break;
          }
        }
        // }
      }
    }

    return isAvailable;
  };

  const isAllocationFormValid = (
    selectedAllocationData,
    isPreparationOverhead,
    isPostOverhead
  ) => {
    let isValid = true;
    let msg = "";

    const requiredFields = [
      "email",
      "allocationTypeObj",
      "startDate",
      "endDate",
    ];

    if (isInternalEngagement) {
      requiredFields.push("clearanceStatus");
    }

    if (isTravelRequiredAvail) {
      requiredFields.push("isTravelRequired");
    }

    if (isHourlyBasis) {
      requiredFields.push("startTime", "endTime", "timeZoneObj");
    }

    if (isPreparationOverhead) {
      requiredFields.push("preparationStartDate");
    }

    if (isPostOverhead) {
      requiredFields.push("postEndDate");
    }

    for (let field of requiredFields) {
      if (
        selectedAllocationData[field] === "" ||
        selectedAllocationData[field] === null ||
        selectedAllocationData[field] === undefined
      ) {
        isValid = false;
        msg = "You need to fill the required fields";
        break;
      }
    }

    if (isValid) {
      const inputValidationFields = ["comment"];

      for (let field of inputValidationFields) {
        if (
          selectedAllocationData[field] &&
          !validateUserInput(selectedAllocationData[field])
        ) {
          isValid = false;
          msg = INPUT_INVALID_MSG;
          break;
        }
      }
    }

    if (isValid) {
      // New allocation related data
      let newAllocStartDate = selectedAllocationData.startDate;
      let newAllocEndDate = selectedAllocationData.endDate;
      let preparationStartDate = selectedAllocationData.preparationStartDate;
      let postEndDate = selectedAllocationData.postEndDate;

      let initStartDate = selectedAllocationData.initStartDate;
      let initEndDate = selectedAllocationData.initEndDate;

      if (
        isNaN(newAllocStartDate) ||
        isNaN(newAllocEndDate) ||
        (isPreparationOverhead && isNaN(preparationStartDate)) ||
        (isPostOverhead && isNaN(postEndDate)) ||
        (isHourlyBasis &&
          (isNaN(selectedAllocationData.startTime) ||
            isNaN(selectedAllocationData.endTime)))
      ) {
        isValid = false;
        msg = "Dates / Time cannot be invalid";
      } else if (
        isTeamAllocPage &&
        !isAllocationStatusEdit &&
        (isPastDateForDefAlloc(newAllocStartDate) ||
          isPastDateForDefAlloc(newAllocEndDate))
      ) {
        isValid = false;
        msg = `Dates cannot be less than  ${ALLOWED_PAST_DAYS_DEF_ALLOC} days from today`;
      } else if (
        !isTeamAllocPage &&
        !isAllocationStatusEdit &&
        (isPastDateForAlloc(newAllocStartDate) ||
          isPastDateForAlloc(newAllocEndDate) ||
          (isPreparationOverhead && isPastDateForAlloc(preparationStartDate)) ||
          (isPostOverhead && isPastDateForAlloc(postEndDate)))
      ) {
        isValid = false;
        msg = `Dates cannot be less than  ${ALLOWED_PAST_DAYS_ALLOC} days from today`;
      } else if (
        isAllocationStatusEdit &&
        isTeamAllocPage &&
        ((initStartDate &&
          initStartDate.getTime() !== newAllocStartDate.getTime() &&
          isPastDateForDefAlloc(newAllocStartDate)) ||
          (initEndDate &&
            initEndDate.getTime() !== newAllocEndDate.getTime() &&
            isPastDateForDefAlloc(newAllocEndDate)))
      ) {
        isValid = false;
        msg = `Dates cannot be less than  ${ALLOWED_PAST_DAYS_DEF_ALLOC} days from today`;
      } else if (
        !isTeamAllocPage &&
        !isAllocationStatusEdit &&
        (newAllocStartDate < data.minStartDate ||
          newAllocStartDate > data.maxStartDate ||
          newAllocEndDate < data.minEndDate ||
          newAllocEndDate > data.maxEndDate)
      ) {
        isValid = false;
        msg = "Start Date / End Date is not within the range";
      } else if (newAllocStartDate > newAllocEndDate) {
        isValid = false;
        msg = "End Date should be higher than Start Date";
      } else if (
        isHourlyBasis &&
        selectedAllocationData.startTime >= selectedAllocationData.endTime
      ) {
        isValid = false;
        msg = "End Time should be higher than Start Time";
      } else if (
        isPreparationOverhead &&
        preparationStartDate >= newAllocStartDate
      ) {
        isValid = false;
        msg = "Pre-Prep Start Date must be less than the allocation Start Date";
      } else if (isPostOverhead && postEndDate <= newAllocEndDate) {
        isValid = false;
        msg = "Post-Prep End Date must be greater than the allocation End Date";
      } else if (
        isPreparationOverhead &&
        preparationStartDate < data.minPrePrepStartDate
      ) {
        isValid = false;
        msg = "Pre-Prep Start Date is not within the range";
      } else if (isPostOverhead && postEndDate > data.maxPostPrepEndDate) {
        isValid = false;
        msg = "Post-Prep End Date is not within the range";
      }
      // else if (isPreparationOverhead && (Math.floor(newAllocStartDate - preparationStartDate) / MILLISECONDS_FOR_DAY) > PRE_ENGAGEMENT_OVERHEAD_DAYS) {
      //     isValid = false;
      //     msg = `Preparation Overhead Start Date should be within ${PRE_ENGAGEMENT_OVERHEAD_DAYS} days of selected Start Date`;
      // } else if (isPostOverhead && (Math.floor(postEndDate - newAllocEndDate) / MILLISECONDS_FOR_DAY) > POST_ENGAGEMENT_OVERHEAD_DAYS) {
      //     isValid = false;
      //     msg = `Post Engagement Overhead End Date should be within ${POST_ENGAGEMENT_OVERHEAD_DAYS} days of selected End Date`;
      // }
    }

    return { isValid: isValid, msg: msg };
  };

  const onClickUpdateRequestStatus = (newStatus) => {
    if (
      newStatus === RequestStatus.COMPLETED ||
      newStatus === RequestStatus.IN_PROGRESS
    ) {
      let confirmDialogDetails =
        ENGAGEMENTS.ENGAGEMENTS_PAGE.MESSAGES.CONFIRM_REQUEST_STATUS_UPDATE;

      dispatchForm({
        type: ACTIONS.UPDATE_REQUEST_STATUS,
        confirmDialogDetails: {
          open: true,
          id: confirmDialogDetails.id,
          title: confirmDialogDetails.title,
          message: confirmDialogDetails.message.replace(
            "$newStatus",
            newStatus
          ),
          engagementId: data.engagementId,
          status: newStatus,
        },
      });
    }
  };

  const updateRequestStatus = (engagementId, status) => {
    let reqData = {
      status: status,
    };

    const endPointUrl =
      AppConfig.baseUrl +
      AppConfig.updateRequestStatus.replace("$id", engagementId);

    handleRequest(
      endPointUrl,
      "PATCH",
      reqData,
      (resData) => {
        if (getEngagementData) {
          getEngagementData();
        } else {
          setRequestStatus(status);
        }
        setMessageBar("Request Status updated successfully", true, "success"); // This is if status !== RequestStatus.CANCELLED
      },
      () => {},
      setIsLoading
    );
  };

  const handleRequestStatusUpdate = (isConfirm) => {
    if (isConfirm) {
      updateRequestStatus(
        confirmDialogDetails.engagementId,
        confirmDialogDetails.status
      );
    }

    dispatchForm({
      type: ACTIONS.UPDATE_REQUEST_STATUS,
      confirmDialogDetails: {},
    });
  };

  const handleUpdateTimelineOnOverlap = (isConfirm) => {
    if (isConfirm) {
      setUpdatePageAuto(true);
    }

    dispatchForm({
      type: ACTIONS.UPDATE_TIMELINE_ON_OVERLAP,
      confirmDialogDetails: {},
    });
  };

  const handleTimelineTimeZoneChange = (event, value) => {
    setTimelineTimeZoneObj(value);
  };

  const handleMessageBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsMessageBarOpen(false);
    setAlertMessage("");
  };

  const setMessageBar = (message, isOpen, type) => {
    setAlertMessage(message);
    setIsMessageBarOpen(isOpen);
    setAlertMessageType(type);
  };

  const onSelectedEmailChange = (value) => {
    setSelectedEmail(value);

    if (
      selectedEmails.length === 1 &&
      Object.keys(selectedEmails[0]).length === 0
    ) {
      setSelectedEmails([value]);
    } else {
      setSelectedEmails([value, ...selectedEmails]);
    }
  };

  const resetSelectedEmails = () => {
    setSelectedEmails([{}]);
    setSelectedEmail("");
  };

  const handleDialogBoxAction = (isConfirm) => {
    switch (confirmDialogDetails.id) {
      case "deleteAllocation":
        return handleDeleteAllocation(isConfirm);
      case "allocateWithOverlaps":
        return handleAllocateOnOVerlap(isConfirm);
      case "updateRequestStatus":
        return handleRequestStatusUpdate(isConfirm);
      case "updateTimelineOnOverlap":
        return handleUpdateTimelineOnOverlap(isConfirm);
      default:
        return;
    }
  };

  const handleDeleteAllocation = (isConfirm) => {
    if (isConfirm) {
      const allocationId = confirmDialogDetails.data.allocationId;
      const endPointUrl =
        AppConfig.baseUrl +
        AppConfig.deleteAllocation.replace("$id", allocationId);
      const uniqueId = confirmDialogDetails.data.uniqueId;

      handleRequest(
        endPointUrl,
        "DELETE",
        null,
        (data) => {
          setAllocationsItemListTeam((allocationsList) =>
            allocationsList.filter((item) => item.id !== uniqueId)
          );

          let dataFromUniqueId = getDataFromAllocationUniqueId(uniqueId);

          let allocationsForSelectedEmail = emailAllocationDetailsMap[
            dataFromUniqueId.email
          ]
            ? emailAllocationDetailsMap[dataFromUniqueId.email].filter(
                (item) => item.id !== dataFromUniqueId.allocationId
              )
            : [];

          setEmailAllocationDetailsMap((prevMap) => {
            return {
              ...prevMap,
              [dataFromUniqueId.email]: allocationsForSelectedEmail,
            };
          });

          setMessageBar("Allocation deleted successfully", true, "success");
        },
        () => {
          setMessageBar(
            "An error occurred in deleting allocation",
            true,
            "error"
          );
        },
        setIsLoading
      );
    }

    dispatchForm({
      type: ACTIONS.DELETE_ALLOCATION,
      confirmDialogDetails: {},
    });
  };

  const updateAllocationComment = useCallback(
    async (id, comment) => {
      const endPointUrl =
        AppConfig.baseUrl + AppConfig.updateAllocation.replace("$id", id);

      let origAddedAllocationsList = [...addedAllocationsList];
      let updateItemIndex = origAddedAllocationsList.findIndex(
        (element) => element.id === id
      );
      let originalComment;

      if (updateItemIndex != -1) {
        originalComment = origAddedAllocationsList[updateItemIndex]["comment"];

        if (originalComment !== comment) {
          handleRequest(
            endPointUrl,
            "PATCH",
            {
              comment: comment,
            },
            (data) => {
              origAddedAllocationsList[updateItemIndex]["comment"] = comment;
              setAddedAllocationsList(origAddedAllocationsList);

              setMessageBar("Comment updated successfully", true, "success");
            },
            (customErrMsg) => {
              setMessageBar(
                customErrMsg
                  ? customErrMsg
                  : "Error occurred in Comment update",
                true,
                "error"
              );
            },
            setIsLoading
          );
        }
      }
    },
    [addedAllocationsList]
  );

  const fetchDataOnSearch = () => {
    setIsResetPageIndex((prev) => !prev);
    getAllocationsByEngagementId();
  };

  const fetchData = (pageSize, pageIndex, filterText, sortBy) => {
    let sortByObj =
      sortBy instanceof Array ? (sortBy.length > 0 ? sortBy[0] : null) : sortBy;
    getAllocationsByEngagementId(
      pageSize,
      pageSize * pageIndex,
      filterText,
      sortByObj
    );
  };

  return (
    <Fragment>
      <Portal>
        <Snackbar
          open={isMessageBarOpen}
          autoHideDuration={6000}
          onClose={handleMessageBarClose}
        >
          <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Portal>

      <AppBar position="static" color={isMainTabs ? "primary" : "secondary"}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="Allocation popup tabs"
          className={classes.tabs}
        >
          {isByMailEnabled && (
            <Tab
              label={
                <Button
                  className={classes.createNewButton}
                  startIcon={<ContactMailIcon />}
                  color={isMainTabs ? "secondary" : "primary"}
                >
                  Search by email
                </Button>
              }
              {...tabProps(tabIndexes.byMailTab)}
              className={classes.tab}
            />
          )}
          {isByTeamEnabled && (
            <Tab
              label={
                <Button
                  startIcon={<GroupIcon />}
                  color={isMainTabs ? "secondary" : "primary"}
                  onClick={() => {}}
                >
                  Search by team
                </Button>
              }
              {...tabProps(tabIndexes.byTeamTab)}
              className={classes.tab}
            />
          )}
          {isBulkUploadEnabled && (
            <Tab
              label={
                <Button
                  startIcon={<PublishIcon />}
                  color={isMainTabs ? "secondary" : "primary"}
                  onClick={() => {}}
                >
                  Bulk Upload
                </Button>
              }
              {...tabProps(tabIndexes.bulkUploadTab)}
              className={classes.tab}
            />
          )}
          {isByPartnerEnabled && (
            <Tab
              label={
                <Button
                  startIcon={<GroupWorkIcon />}
                  color={isMainTabs ? "secondary" : "primary"}
                  onClick={() => {}}
                >
                  Allocate Partners
                </Button>
              }
              {...tabProps(tabIndexes.byPartnerTab)}
              className={classes.tab}
            />
          )}
        </Tabs>
      </AppBar>
      {isByMailEnabled && (
        <TabPanel
          value={tab}
          index={tabIndexes.byMailTab}
          // <TabPanel value={tab} index={0}
          children={
            <div className={classes.page}>
              <Grid container spacing={3}>
                <AllocationsByMail
                  isInternalEngagement={isInternalEngagement}
                  isMarketingEvent={isMarketingEvent}
                  timelineStartDate={timelineStartDate}
                  timelineEndDate={timelineEndDate}
                  engStartDate={data.startDate}
                  engEndDate={data.endDate}
                  requestStatus={requestStatus}
                  onClickAddBtn={onClickAddBtn}
                  selectedEmail={selectedEmail}
                  onSelectedEmailChange={onSelectedEmailChange}
                  selectedEmails={selectedEmails}
                  setTimelineStartDate={setTimelineStartDate}
                  setTimelineEndDate={setTimelineEndDate}
                  allocationsItemList={allocationsItemList}
                  isEngagementDataPanelAvail={isEngagementDataPanelAvail}
                  data={data}
                  setMessageBar={setMessageBar}
                  header={searchByMailHeader}
                  headerActionBtnVisible={headerActionBtnVisible}
                  onClickUpdateRequestStatus={onClickUpdateRequestStatus}
                  isTimelineTimeZoneEnabled={isTimelineTimeZoneEnabled}
                  timelineTimeZoneObj={timelineTimeZoneObj}
                  handleTimelineTimeZoneChange={handleTimelineTimeZoneChange}
                  getAllocationsByEmail={getAllocationsByEmail}
                  setOpenBackDropProgress={setIsLoading}
                  resetPage={resetSelectedEmails}
                />
              </Grid>
            </div>
          }
        ></TabPanel>
      )}

      {isByTeamEnabled && (
        <TabPanel
          value={tab}
          index={tabIndexes.byTeamTab}
          // <TabPanel value={tab} index={1}
          children={
            <div className={classes.page}>
              <Grid container spacing={3}>
                <AllocationsByTeam
                  isInternalEngagement={isInternalEngagement}
                  isMarketingEvent={isMarketingEvent}
                  timelineStartDate={timelineStartDate}
                  timelineEndDate={timelineEndDate}
                  engStartDate={data.startDate}
                  engEndDate={data.endDate}
                  requestStatus={requestStatus}
                  isSearchByAllocTypeEnabled={isSearchByAllocTypeEnabled}
                  onClickAddBtn={onClickAddBtn}
                  allocationsItemListTeam={allocationsItemListTeam}
                  isEngagementDataPanelAvail={isEngagementDataPanelAvail}
                  data={data}
                  header={searchByTeamHeader}
                  headerActionBtnVisible={headerActionBtnVisible}
                  isSearchByDatesEnabled={isSearchByDatesEnabled}
                  getAllocationsByEmail={getAllocationsByEmail}
                  setMessageBar={setMessageBar}
                  onAllocationItemDoubleClick={onAllocationItemDoubleClick}
                  setAllocationsItemListTeam={setAllocationsItemListTeam}
                  onClickUpdateRequestStatus={onClickUpdateRequestStatus}
                  isTimelineTimeZoneEnabled={isTimelineTimeZoneEnabled}
                  timelineTimeZoneObj={timelineTimeZoneObj}
                  handleTimelineTimeZoneChange={handleTimelineTimeZoneChange}
                  setTimelineStartDate={setTimelineStartDate}
                  setTimelineEndDate={setTimelineEndDate}
                  isTeamAllocPage={isTeamAllocPage}
                  updatePageAuto={updatePageAuto}
                  setUpdatePageAuto={setUpdatePageAuto}
                  selectedStartDateAuto={selectedAllocationData.startDate}
                  selectedEndDateAuto={selectedAllocationData.endDate}
                  setOpenBackDropProgress={setIsLoading}
                />
              </Grid>
            </div>
          }
        ></TabPanel>
      )}

      {isBulkUploadEnabled && (
        <TabPanel
          value={tab}
          index={tabIndexes.bulkUploadTab}
          // <TabPanel value={tab} index={2}
          children={
            <div className={classes.page}>
              <Grid container spacing={3}>
                <BulkUpload header={bulkUploadHeader} />
              </Grid>
            </div>
          }
        ></TabPanel>
      )}

      {isByPartnerEnabled && (
        <TabPanel
          value={tab}
          index={tabIndexes.byPartnerTab}
          // <TabPanel value={tab} index={2}
          children={
            <div className={classes.page}>
              <Grid container spacing={3}>
                <AllocationsByPartner
                  isInternalEngagement={isInternalEngagement}
                  isMarketingEvent={isMarketingEvent}
                  timelineStartDate={timelineStartDate}
                  timelineEndDate={timelineEndDate}
                  engStartDate={data.startDate}
                  engEndDate={data.endDate}
                  setTimelineStartDate={setTimelineStartDate}
                  setTimelineEndDate={setTimelineEndDate}
                  requestStatus={requestStatus}
                  header={searchByPartnerHeader}
                  headerActionBtnVisible={headerActionBtnVisible}
                  isEngagementDataPanelAvail={isEngagementDataPanelAvail}
                  data={data}
                  setAllocationsItemListPartner={setAllocationsItemListPartner}
                  allocationsItemListPartner={allocationsItemListPartner}
                  onClickAddBtn={onClickAddBtn}
                  setMessageBar={setMessageBar}
                  getAllocationsByEmail={getAllocationsByEmail}
                  onClickUpdateRequestStatus={onClickUpdateRequestStatus}
                  isTimelineTimeZoneEnabled={isTimelineTimeZoneEnabled}
                  timelineTimeZoneObj={timelineTimeZoneObj}
                  handleTimelineTimeZoneChange={handleTimelineTimeZoneChange}
                  setOpenBackDropProgress={setIsLoading}
                />
              </Grid>
            </div>
          }
        ></TabPanel>
      )}

      {allocRecordsTableAvail && addedAllocationsList && (
        <AllocationRecordsTable
          isInternalEngagement={isInternalEngagement}
          addedAllocationsList={addedAllocationsList}
          setTablePageSize={setTablePageSize}
          fetchData={fetchData}
          isResetPageIndex={isResetPageIndex}
          setFilterText={setFilterText}
          setSortBy={setSortBy}
          openUpdateAllocationStatusPopup={openUpdateAllocationStatusPopup}
          updateComment={updateAllocationComment}
        />
      )}

      <AddNewAllocationPopup
        title={title}
        data={selectedAllocationData}
        open={
          Boolean(
            allocationsPopupDetails &&
              Object.values(allocationsPopupDetails).length
          ) && allocationsPopupDetails.open
        }
        setAllocationData={handleAllocationData}
        handleFormAction={handleAllocationsSubmission}
        isAllocationStatusEdit={isAllocationStatusEdit}
        statusList={statusList}
        isHourlyBasis={isHourlyBasis}
        setIsHourlyBasis={setIsHourlyBasis}
        isTravelRequiredAvail={isTravelRequiredAvail}
        isAllocTypeSelect={isAllocTypeSelect}
        isHourlyBasisDisabled={isHourlyBasisDisabled}
        isTeamAllocPage={isTeamAllocPage}
        isAddOverheadEnabled={isPaidEngagement(
          selectedAllocationData.engagementTypeId
        )}
        isSubmitBtnClicked={isSubmitBtnClicked}
        isInternalEngagement={isInternalEngagement}
      />
      <ConfirmationDialog
        data={confirmDialogDetails}
        open={
          Boolean(
            confirmDialogDetails && Object.values(confirmDialogDetails).length
          ) && confirmDialogDetails.open
        }
        handleSubmit={handleDialogBoxAction}
        dialogType={dialogType}
      />

      <BackdropProgress open={isLoading || isGetAllocationsInProgress} />
    </Fragment>
  );
};

export default AllocationsBy;
