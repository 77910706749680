import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import { DIALOG_TYPES } from "./utils/AppConstants";

const ConfirmationDialog = ({
  data,
  open,
  handleSubmit,
  dialogType,
  disableCloseOnClickOutside,
  confirmFalseBtnTxt,
  confirmTrueBtnTxt,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    handleSubmit(false);
  };

  const handleConfirm = () => {
    handleSubmit(true);
  };

  const isSubmisssion = () => {
    return dialogType === DIALOG_TYPES.SUBMIT_DIALOG;
  };

  const isConfirmation = () => {
    return dialogType === DIALOG_TYPES.CONFIRM_DIALOG;
  };

  const isYesNoConfirmation = () => {
    return dialogType === DIALOG_TYPES.YES_NO_DIALOG;
  };

  const isInfo = () => {
    return dialogType === DIALOG_TYPES.INFO_DIALOG;
  };

  const isCustom = () => {
    return dialogType === DIALOG_TYPES.CUSTOM;
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={!disableCloseOnClickOutside && handleClose}
      aria-labelledby="confirm-dialog-title"
    >
      <DialogTitle id="confirm-dialog-title">
        <Typography variant="h6" style={{ fontSize: "1.1rem" }}>
          {data.title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{data.message}</DialogContentText>
        <DialogContentText>{data.additionalMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {isSubmisssion() && (
          <React.Fragment>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ padding: "7px 20px" }}
            >
              Close
            </Button>
            <Button
              onClick={handleConfirm}
              color="secondary"
              variant="outlined"
              style={{ margin: 20 }}
            >
              Submit
            </Button>
          </React.Fragment>
        )}

        {isConfirmation() && (
          <React.Fragment>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ padding: "7px 20px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              color="secondary"
              variant="outlined"
              style={{ margin: 20 }}
            >
              Confirm
            </Button>
          </React.Fragment>
        )}

        {isYesNoConfirmation() && (
          <React.Fragment>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ padding: "7px 20px" }}
            >
              No
            </Button>
            <Button
              onClick={handleConfirm}
              color="secondary"
              variant="outlined"
              style={{ margin: 20 }}
            >
              Yes
            </Button>
          </React.Fragment>
        )}

        {isInfo() && (
          <React.Fragment>
            <Button
              onClick={handleConfirm}
              color="secondary"
              variant="outlined"
              style={{ padding: "7px 20px" }}
            >
              OK
            </Button>
          </React.Fragment>
        )}

        {isCustom() && (
          <React.Fragment>
            <Button
              onClick={handleClose}
              color="primary"
              style={{ margin: 20 }}
            >
              {confirmFalseBtnTxt}
            </Button>
            <Button
              onClick={handleConfirm}
              color="secondary"
              variant="outlined"
              style={{ margin: 20 }}
            >
              {confirmTrueBtnTxt}
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
