import React, {
  Fragment,
  useCallback,
  useMemo,
  useEffect,
  useReducer,
  useState,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Link from "@material-ui/core/Link";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { useHistory } from "react-router-dom";

import ConfirmationDialog from "../ConfirmationDialog";
import AllocationsModalPopup from "../allocations/AllocationsModalPopup";
import CircularProgressIndeterminate from "../CircularProgressIndeterminate";
import FundingSource from "./FundingSource";
import {
  AppConfig,
  FormConstants,
  RequestStatus,
  Privileges,
  DATE_DISPLAY_FORMAT,
  PAGES,
} from "../../Config";
import {
  countries,
  engagementNatureOptions,
  eventNatureOptions,
} from "../utils/AppData";
import BackdropProgress from "../BackdropProgress";

import {
  getDateFromString,
  formatDateWithDashes,
  formatDateWithDashesToInput,
  generateUUID,
  isPastDateForEng,
  getAllowedPastDateForEng,
  isPastDateForAlloc,
  getAllowedPastDateForAlloc,
  isInternal,
  isPaidEngagement,
  isNonPaidPostSale,
  isNonPaidPreSale,
  getDateObjectFromString,
  isAccessGranted,
  validateUserInput,
  isMarketingEvent,
  isOnApprovalProcess,
} from "../utils/utils";
import useHttp from "../utils/http";
import { useAllocationTypes } from "../DataContext";

import {
  ENGAGEMENTS,
  DIALOG_TYPES,
  PRE_ENGAGEMENT_RELAX_PERIOD_DAYS,
  POST_ENGAGEMENT_RELAX_PERIOD_DAYS,
  ALLOCATION_TYPE_ACTIVE_STATUS,
  INPUT_INVALID_MSG,
  ALLOWED_PAST_DAYS_ENG,
  ALLOWED_PAST_DAYS_ENG_INTERNAL,
} from "../utils/AppConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "10%",
    minWidth: 100,
  },
  flexGrid: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    alignItems: "center",
  },
  typeItem: {
    minWidth: 250,
  },
  heading: {
    width: "100%",
    textAlign: "left",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: "#fff",
  },
  paddedGrid: {
    paddingLeft: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
  },

  formControlHalf: {
    margin: theme.spacing(1),
    minWidth: 250,
  },

  btnControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const getTypeSpecificProps = (type) => {
  const formProps = {
    [FormConstants.EngagementTypes.Paid.id]: {
      // Paid
      isAccountDisabled: false,
      isOpportunitySelectAvail: true,
      isProductAvail: true,
      isDescriptionAvail: true,
      isSalesSubRegionDisabled: true,
      isProductUnitDisabled: true,
      isBusinessUnitDisabled: true,
      isStartEndDatesDisabled: true,
      isTentativeDateDisabled: true,
      isCustomerContactDisabled: false,
      isBusinessDomainDisabled: true,
      isContractSignedDisabled: true,
      isCustomerContactAvail: true,
      isBusinessDomainAvail: false,
      isOpportunityOwnerAvail: true,
      isTechnicalOwnerAvail: true,
      isContractSignedAvail: true,
      isMultipleFundingSourcesEnabled: false,
      isDescriptionDisabled: true,
      isTentativeDateAvail: true,
      isAllocationTypeDisabled: true,
    },
    [FormConstants.EngagementTypes.NonPaidPreSale.id]: {
      // Non Paid Pre Sale
      isAccountDisabled: false,
      isOpportunitySelectAvail: true,
      isProductAvail: false,
      isDescriptionAvail: false,
      isSalesSubRegionDisabled: true,
      isProductUnitDisabled: false,
      isBusinessUnitDisabled: false,
      isStartEndDatesDisabled: false,
      isTentativeDateDisabled: false,
      isCustomerContactDisabled: false,
      isBusinessDomainDisabled: false,
      isContractSignedDisabled: false,
      isCustomerContactAvail: true,
      isBusinessDomainAvail: true,
      isOpportunityOwnerAvail: true,
      isTechnicalOwnerAvail: true,
      isContractSignedAvail: true,
      isMultipleFundingSourcesEnabled: false,
      isDescriptionDisabled: true,
      isTentativeDateAvail: true,
      isAllocationTypeDisabled: false,
    },
    [FormConstants.EngagementTypes.NonPaidPostSale.id]: {
      // Non Paid Post Sale
      isAccountDisabled: false,
      isOpportunitySelectAvail: true,
      isProductAvail: true,
      isDescriptionAvail: false,
      isSalesSubRegionDisabled: true,
      isProductUnitDisabled: false,
      isBusinessUnitDisabled: false,
      isStartEndDatesDisabled: false,
      isTentativeDateDisabled: false,
      isCustomerContactDisabled: false,
      isBusinessDomainDisabled: false,
      isContractSignedDisabled: false,
      isCustomerContactAvail: true,
      isBusinessDomainAvail: true,
      isOpportunityOwnerAvail: true,
      isTechnicalOwnerAvail: true,
      isContractSignedAvail: true,
      isMultipleFundingSourcesEnabled: false,
      isDescriptionDisabled: true,
      isTentativeDateAvail: true,
      isAllocationTypeDisabled: false,
    },
    [FormConstants.EngagementTypes.InternalEngagement.id]: {
      // Internal Engagement
      isAccountDisabled: true,
      isOpportunitySelectAvail: false,
      isProductAvail: false,
      isDescriptionAvail: true,
      isSalesSubRegionDisabled: false,
      isProductUnitDisabled: false,
      isBusinessUnitDisabled: false,
      isStartEndDatesDisabled: false,
      isTentativeDateDisabled: true,
      isCustomerContactDisabled: true,
      isBusinessDomainDisabled: true,
      isContractSignedDisabled: true,
      isCustomerContactAvail: false,
      isBusinessDomainAvail: false,
      isOpportunityOwnerAvail: false,
      isTechnicalOwnerAvail: false,
      isContractSignedAvail: false,
      isMultipleFundingSourcesEnabled: true,
      isDescriptionDisabled: false,
      isTentativeDateAvail: false,
      isAllocationTypeDisabled: false,
    },
    [FormConstants.EngagementTypes.MarketingEvent.id]: {
      // Marketing Event
      isAccountDisabled: true,
      isOpportunitySelectAvail: false,
      isProductAvail: false,
      isDescriptionAvail: true,
      isSalesSubRegionDisabled: false,
      isProductUnitDisabled: false,
      isBusinessUnitDisabled: false,
      isStartEndDatesDisabled: false,
      isTentativeDateDisabled: true,
      isCustomerContactDisabled: true,
      isBusinessDomainDisabled: true,
      isContractSignedDisabled: true,
      isCustomerContactAvail: false,
      isBusinessDomainAvail: false,
      isOpportunityOwnerAvail: false,
      isTechnicalOwnerAvail: false,
      isContractSignedAvail: false,
      isMultipleFundingSourcesEnabled: true,
      isDescriptionDisabled: false,
      isTentativeDateAvail: false,
      isAllocationTypeDisabled: false,
    },
  };

  return formProps[type];
};

const ACTIONS = {
  SUBMIT_ENGAGEMENT: "SUBMIT_ENGAGEMENT",
  SET_INTERNAL_ALLOCATIONS: "SET_INTERNAL_ALLOCATIONS",
  OPEN_INTERNAL_ALLOCATIONS_POPUP: "OPEN_INTERNAL_ALLOCATIONS_POPUP",
  UPDATE_ENGAGEMENT: "UPDATE_ENGAGEMENT",
  // HANDLE_SUCCESS: 'HANDLE_SUCCESS'
  APPROVE_ENGAGEMENT: "APPROVE_ENGAGEMENT",
};

const EngagementForm = ({
  formTitle,
  setTab,
  tabIndex,
  isEditMode,
  initialFormProps,
  engagementId,
  setMessageBar,
  isInternalEngagement,
  buttonLable,
  isOnApprovalProcess,
}) => {
  const classes = useStyles();
  const history = useHistory();
  let allAllocationTypes = useAllocationTypes();
  const initialFundingSources = [
    {
      businessUnit: "",
      productUnit: "",
      region: "",
      subRegion: "",
      percentage: 100,
    },
  ];

  const { handleRequest } = useHttp();

  const [engagementData, setEngagementData] = useState({
    engagementId: "",
    type: "",
    ...(isInternalEngagement
      ? { account: FormConstants.Wso2InternalAccount }
      : { account: "" }),
    opportunity: "",
    product: "",
    description: "",
    allocationType: "",
    allocationTypeId: "",
    engagementCode: "",
    engagementNature: "",
    country: "",
    salesSubRegion: "",
    productUnit: "",
    businessUnit: "",
    address: "",
    startDate: null,
    endDate: null,
    isTentativeDates: false,
    customerContact: "",
    businessDomain: "",
    opportunityOwner: "",
    technicalOwner: "",
    isContractsSigned: false,
    engagementScope: "",
    comment: "",
    productId: "",
  });

  const [originalEngagementData, setOriginalEngagementData] = useState({
    engagementId: "",
    type: "",
    ...(isInternalEngagement
      ? { account: FormConstants.Wso2InternalAccount }
      : { account: "" }),
    opportunity: "",
    product: "",
    description: "",
    allocationType: "",
    allocationTypeId: "",
    engagementCode: "",
    engagementNature: "",
    country: "",
    salesSubRegion: "",
    productUnit: "",
    businessUnit: "",
    address: "",
    startDate: null,
    endDate: null,
    isTentativeDates: false,
    customerContact: "",
    businessDomain: "",
    opportunityOwner: "",
    technicalOwner: "",
    isContractsSigned: false,
    engagementScope: "",
    comment: "",
    productId: "",
  });

  const [formProps, setFormProps] = useState(
    initialFormProps
      ? initialFormProps
      : {
          isAccountDisabled: false,
          isOpportunitySelectAvail: true,
          isProductAvail: true,
          isDescriptionAvail: false,
          isSalesSubRegionDisabled: true,
          isProductUnitDisabled: false,
          isBusinessUnitDisabled: false,
          isStartEndDatesDisabled: true,
          isTentativeDateDisabled: true,
          isCustomerContactDisabled: false,
          isBusinessDomainDisabled: true,
          isContractSignedDisabled: true,
          isCustomerContactAvail: true,
          isBusinessDomainAvail: true,
          isOpportunityOwnerAvail: true,
          isTechnicalOwnerAvail: true,
          isContractSignedAvail: true,
          isMultipleFundingSourcesEnabled: false,
          isDescriptionDisabled: true,
          isTentativeDateAvail: true,
          isAllocationTypeDisabled: false,
        }
  );

  const [engagementTypes, setEngagementTypes] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [products, setProducts] = useState([]);
  const [allocationTypes, setAllocationTypes] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [productUnits, setProductUnits] = useState([]);
  const [subRegions, setSubRegions] = useState([]);
  const [regions, setRegions] = useState([]);
  const [addedFundingSources, setAddedFundingSources] = useState(
    initialFundingSources
  );

  const [addedEventNamesList, setAddedEventNamesList] = useState([]);

  const [isAccountsLoading, setIsAccountsLoading] = useState(false);
  const [isOpportunitiesLoading, setIsOpportunitiesLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [isNewDatesFetching, setIsNewDatesFetching] = useState(false);
  const [originalFundingSources, setOriginalFundingSources] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // const [isFormValidated, setIsFormValidated] = useState(false);
  const [isSubmitBtnClicked, setIsSubmitBtnClicked] = useState(false);
  // const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
  // const [alertMessage, setAlertMessage] = useState("");
  // const [alertMessageType, setAlertMessageType] = useState("error");

  const [allocationRequestedProducts, setAllocationRequestedProducts] =
    useState([]);
  const [accountsMap, setAccountsMap] = useState({});
  const [engagementCodeMap, setEngagementCodeMap] = useState({});
  const [engagementCodeSuffixMap, setEngagementCodeSuffixMap] = useState({});
  const [opportunitiesMap, setOpportunitiesMap] = useState({});
  const [productsMap, setProductsMap] = useState({});

  const [salesForceLink, setSalesForceLink] = useState("");
  const [showSalesForceLink, setShowSalesForceLink] = useState(false);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const [descriptionPlaceholder, setDescriptionPlaceholder] = useState("");

  const engagementReducer = (state, action) => {
    switch (action.type) {
      case ACTIONS.SUBMIT_ENGAGEMENT:
        return {
          ...state,
          confirmDialogDetails: action.confirmDialogDetails,
          dialogType: DIALOG_TYPES.SUBMIT_DIALOG,
          disableCloseOnClickOutside: false,
        };

      case ACTIONS.SET_INTERNAL_ALLOCATIONS:
        return {
          ...state,
          confirmDialogDetails: action.confirmDialogDetails,
          dialogType: DIALOG_TYPES.YES_NO_DIALOG,
          disableCloseOnClickOutside: true,
        };

      case ACTIONS.OPEN_INTERNAL_ALLOCATIONS_POPUP:
        return {
          ...state,
          allocationsPopupDetails: action.allocationsPopupDetails,
        };

      case ACTIONS.UPDATE_ENGAGEMENT:
        return {
          ...state,
          confirmDialogDetails: action.confirmDialogDetails,
          dialogType: DIALOG_TYPES.SUBMIT_DIALOG,
          disableCloseOnClickOutside: false,
        };

      case ACTIONS.APPROVE_ENGAGEMENT:
        return {
          ...state,
          confirmDialogDetails: action.confirmDialogDetails,
          dialogType: DIALOG_TYPES.SUBMIT_DIALOG,
          disableCloseOnClickOutside: false,
        };

      default:
        throw new Error("Should not get here");
    }
  };

  const [
    {
      confirmDialogDetails,
      dialogType,
      disableCloseOnClickOutside,
      dialogName,
      allocationsPopupDetails,
    },
    dispatchForm,
  ] = useReducer(engagementReducer, {
    confirmDialogDetails: { open: false },
    dialogType: "",
    disableCloseOnClickOutside: false,
    dialogName: "",
    allocationsPopupDetails: {},
  });

  const getEngagementTypes = useCallback(async () => {
    const endPointUrl =
      AppConfig.baseUrl +
      AppConfig.getEngagementTypes +
      `?type=${
        isInternalEngagement
          ? FormConstants.Types.Internal
          : FormConstants.Types.Customer
      }`;

    handleRequest(
      endPointUrl,
      "GET",
      null,
      (resData) => {
        if (isInternalEngagement) {
          let engTypes = [];

          if (
            isAccessGranted([Privileges.INTERNAL_ENGAGEMENT_CREATE_PAGE]) &&
            resData.find(
              (item) =>
                item.id === FormConstants.EngagementTypes.InternalEngagement.id
            )
          ) {
            engTypes.push(
              resData.find(
                (item) =>
                  item.id ===
                  FormConstants.EngagementTypes.InternalEngagement.id
              )
            );
          }

          if (
            isAccessGranted([Privileges.MARKETING_EVENT_CREATE_PAGE]) &&
            resData.find(
              (item) =>
                item.id === FormConstants.EngagementTypes.MarketingEvent.id
            )
          ) {
            engTypes.push(
              resData.find(
                (item) =>
                  item.id === FormConstants.EngagementTypes.MarketingEvent.id
              )
            );
          }

          setEngagementTypes(engTypes);
        } else {
          setEngagementTypes(resData);
        }
      },
      () => {
        setMessageBar(
          "Error occurred in loading Engagement Types",
          true,
          "error"
        );
      }
    );
  }, [isInternalEngagement]);

  // Get all line items
  const getAllProducts = useCallback(async () => {
    const endPointUrl = `${AppConfig.baseUrl}${AppConfig.getAllProducts}?engagementTypes=Paid`;

    handleRequest(
      endPointUrl,
      "GET",
      null,
      (data) => {
        let items = [];

        data.forEach((item) => {
          items.push(item.productId);
        });

        setAllocationRequestedProducts(items);
      },
      () => {
        setMessageBar(
          "Error occurred in loading allocated Products",
          true,
          "warning"
        );
      }
    );
  }, []);

  const getAccounts = useCallback(async () => {
    const endPointUrl = AppConfig.baseUrl + AppConfig.getAccounts;

    handleRequest(
      endPointUrl,
      "GET",
      null,
      (accounts) => {
        let accountsMap = {};

        accounts.forEach((account) => {
          accountsMap[account.id] = {
            id: account.id,
            salesSubRegion: account.subRegion,
            salesSubRegionObj: subRegions.find(
              (region) => region.name === account.subRegion
            ),
          };
        });

        let accountsList = accounts.map((account) => ({
          id: account.id,
          name: account.name,
        }));

        setAccounts(accountsList);
        setAccountsMap(accountsMap);
      },
      () => {
        setMessageBar("Error occurred in loading Accounts", true, "error");
      },
      setIsAccountsLoading
    );
  }, [subRegions]);

  const getOpportunities = useCallback(async (type, accountId) => {
    // const endPointUrl = AppConfig.baseUrl + (isNonPaidPostSale(type) ? AppConfig.getOpportunitiesPostSale : AppConfig.getOpportunities);
    const endPointUrl =
      AppConfig.baseUrl + AppConfig.getOpportunities.replace("$id", accountId);
    const params = `isOnlyActiveOpportunities=${!isNonPaidPostSale(type)}`;
    const requestUrl = `${endPointUrl}?${params}`;

    handleRequest(
      requestUrl,
      "GET",
      null,
      (opportunities) => {
        setMessageBar("", false);

        let opportunitiesMap = {};

        opportunities.forEach((opportunity) => {
          opportunity.preSalesCode = opportunity.preSalesCode.split("(")[0];

          opportunitiesMap[opportunity.id] = {
            name: opportunity.name,
            isWon: opportunity.isWon,
            preSaleEngagementCode: opportunity.preSalesCode,
            postSaleEngagementCode: opportunity.postSalesEngagementCode,
            address: opportunity.customerAddress?.replace(/<br>/g, " "),
            technicalOwner: opportunity.technicalOwner,
            opportunityOwner: opportunity.owner?.name,
          };
        });

        let opportunitiesList = opportunities.map((opportunity) => ({
          id: opportunity.id,
          name: opportunity.name,
        }));

        if (opportunities.length < 1) {
          setMessageBar(
            "No opportunities found for the selected Account",
            true,
            "info"
          );
        }

        setOpportunities(opportunitiesList);
        setOpportunitiesMap(opportunitiesMap);
      },
      () => {
        setMessageBar("Error occurred in loading Opportunities", true, "error");
      },
      setIsOpportunitiesLoading
    );
  }, []);

  const getProducts = useCallback(
    async (opportunityId) => {
      const endPointUrl =
        AppConfig.baseUrl + AppConfig.getProducts.replace("$id", opportunityId);

      handleRequest(
        endPointUrl,
        "GET",
        null,
        (products) => {
          setMessageBar("", false);

          let productsMap = {};

          products.forEach((product) => {
            productsMap[product.id] = {
              name: product.name,
              engagementCodeAuto: product.engagementCodeAuto,
              startDate: product.serviceStartDate,
              endDate: product.serviceEndDate,
              isTentativeDates: product.isTentativeDates,
              description: product.description ? product.description : "",
              productUnit: product.productUnit,
              productUnitObj: productUnits.find(
                (item) => item.name === product.productUnit
              ),
              businessUnit: product.businessUnit,
              businessUnitObj: businessUnits.find(
                (item) => item.name === product.businessUnit
              ),
            };
          });

          let productsList = products.map((product) => ({
            id: product.id,
            name: product.name,
            description: product.description ? product.description : "",
          }));

          if (products.length < 1) {
            setMessageBar(
              "No line items found for the selected Opportunity",
              true,
              "info"
            );
          }

          setProducts(productsList);
          setProductsMap(productsMap);
        },
        () => {
          setMessageBar("Error occurred in loading Products", true, "error");
        },
        setIsProductsLoading
      );
    },
    [productUnits, businessUnits]
  );

  const getBusinessUnitsList = useCallback(async () => {
    handleRequest(
      AppConfig.baseUrl + AppConfig.getBusinessUnitsList,
      "GET",
      null,
      (businessUnitsList) => {
        setBusinessUnits(
          isEditMode
            ? businessUnitsList
            : businessUnitsList.filter((item) => item.isActive)
        );
      },
      () => {
        setMessageBar(
          "Error occurred in loading Business Units",
          true,
          "error"
        );
      },
      setIsLoading
    );
  }, [isEditMode]);

  const getProductUnitsList = useCallback(async () => {
    handleRequest(
      AppConfig.baseUrl + AppConfig.getProductUnitsList,
      "GET",
      null,
      (productUnitsList) => {
        setProductUnits(
          isEditMode
            ? productUnitsList
            : productUnitsList.filter((item) => item.isActive)
        );
      },
      () => {
        setMessageBar("Error occurred in loading Product Units", true, "error");
      },
      setIsLoading
    );
  }, [isEditMode]);

  const getRegionsList = useCallback(async () => {
    handleRequest(
      AppConfig.baseUrl + AppConfig.getRegionsList,
      "GET",
      null,
      (regionsList) => {
        setRegions(
          isEditMode ? regionsList : regionsList.filter((item) => item.isActive)
        );
      },
      () => {
        setMessageBar("Error occurred in loading Regions", true, "error");
      },
      setIsLoading
    );
  }, [isEditMode]);

  const getSubRegionsList = useCallback(async () => {
    handleRequest(
      AppConfig.baseUrl + AppConfig.getSubRegionsList,
      "GET",
      null,
      (salesSubRegionsList) => {
        setSubRegions(
          isEditMode
            ? salesSubRegionsList
            : salesSubRegionsList.filter((item) => item.isActive)
        );
      },
      () => {
        setMessageBar("Error occurred in loading Sub Regions", true, "error");
      },
      setIsLoading
    );
  }, [isEditMode]);

  const getFundingSources = useCallback(async () => {
    getBusinessUnitsList();
    getProductUnitsList();
    getRegionsList();
    getSubRegionsList();
  }, []);

  useEffect(() => {
    if (
      isEditMode &&
      businessUnits.length > 0 &&
      productUnits.length > 0 &&
      regions.length > 0 &&
      subRegions.length > 0
    ) {
      getEngagementData();
    }
  }, [isEditMode, businessUnits, productUnits, regions, subRegions]);

  const getAllocationTypesForApprovalProcess = useCallback(
    async (type) => {
      const endPointUrl = AppConfig.baseUrl + AppConfig.getAllocationTypes;
      const requestUrl = `${endPointUrl}?engagementType=${type}&allocationTypeStatus=${ALLOCATION_TYPE_ACTIVE_STATUS}`;

      return handleRequest(requestUrl, "GET", null, (types) => {
        const engagementCodeMap = {};
        const engagementCodeSuffixMap = {};
        // let activeTypes = types.filter(typeObj => typeObj.status === ALLOCATION_TYPE_ACTIVE_STATUS);
        const typesList = types.map((item) => ({
          id: item.id,
          allocationTypeName: item.allocationTypeName,
          engagementTypeId: item.engagementTypeId,
          department: item.department,
          emailToAllocations: item.emailToAllocations,
          defaultEngagementCode: item.defaultEngagementCode,
          engagementCodeSuffix: item.engagementCodeSuffix,
        }));

        setAllocationTypes(typesList);

        types.forEach((item) => {
          // old code used type.allocationType for mapping
          engagementCodeMap[item.id] = item.defaultEngagementCode;

          if (item.engagementCodeSuffix) {
            engagementCodeSuffixMap[item.id] = item.engagementCodeSuffix;
          }
        });

        setEngagementCodeMap(engagementCodeMap);
        setEngagementCodeSuffixMap(engagementCodeSuffixMap);

        return typesList;
      });
    },
    [setAllocationTypes, setEngagementCodeMap, setEngagementCodeSuffixMap]
  );

  const getAllocationTypes = useCallback(async (type) => {
    const endPointUrl = AppConfig.baseUrl + AppConfig.getAllocationTypes;
    const requestUrl = `${endPointUrl}?${
      !isEditMode &&
      `engagementType=${type}&allocationTypeStatus=${ALLOCATION_TYPE_ACTIVE_STATUS}`
    }`;
    handleRequest(requestUrl, "GET", null, (types) => {
      let engagementCodeMap = {};
      let engagementCodeSuffixMap = {};
      let typesList = types.map((type) => ({
        id: type.id,
        allocationTypeName: type.allocationTypeName,
        engagementTypeId: type.engagementTypeId,
        department: type.department,
        emailToAllocations: type.emailToAllocations,
      }));

      setAllocationTypes(typesList);

      types.forEach((type) => {
        engagementCodeMap[type.id] = type.defaultEngagementCode;

        if (type.engagementCodeSuffix) {
          engagementCodeSuffixMap[type.id] = type.engagementCodeSuffix;
        }
      });

      setEngagementCodeMap(engagementCodeMap);
      setEngagementCodeSuffixMap(engagementCodeSuffixMap);
    });
  }, []);

  const setDataOnEngagementType = (type, productUnit) => {
    if (isPaidEngagement(type)) {
      if (productUnit) {
        setFormProps((prevFormProps) => ({
          ...prevFormProps,
          isProductUnitDisabled: true,
          isFetchNewDatesButtonAvail: true,
        }));
      } else {
        setFormProps((prevFormProps) => ({
          ...prevFormProps,
          isProductUnitDisabled: false,
          isFetchNewDatesButtonAvail: true,
        }));
      }
    } else {
      setFormProps((prevFormProps) => ({
        ...prevFormProps,
        isStartEndDatesDisabled: false,
      }));
    }
  };

  const getNewDates = useCallback(async (engagementData) => {
    const endPointUrl =
      AppConfig.baseUrl +
      AppConfig.getNewDates.replace("$id", engagementData.productId);

    handleRequest(
      endPointUrl,
      "GET",
      null,
      (record) => {
        if (record) {
          setEngagementData((prevEngagementData) => {
            if (
              getDateFromString(prevEngagementData.startDate) ===
                record.serviceStartDate &&
              getDateFromString(prevEngagementData.endDate) ===
                record.serviceEndDate
            ) {
              setMessageBar("Engagement dates are up to date", true, "info");
              return prevEngagementData;
            } else {
              setMessageBar(
                "Engagement dates have been changed at Salesforce, Please click Submit to update engagement with new dates",
                true,
                "info"
              );
              setIsSubmitBtnDisabled(false);

              return {
                ...prevEngagementData,
                startDate: record.serviceStartDate,
                endDate: record.serviceEndDate,
              };
            }
          });
        } else {
          setMessageBar("Salesforce data is not available", true, "warning");
        }
      },
      () => {
        setMessageBar("Error occurred in fetching new dates", true, "error");
      },
      setIsNewDatesFetching
    );
  }, []);

  const getEngagementData = useCallback(async () => {
    if (engagementId) {
      let endPointUrl =
        AppConfig.baseUrl +
        AppConfig.getEngagementById.replace("$id", engagementId);

      handleRequest(endPointUrl, "GET", null, (engagement) => {
        if (engagement) {
          setFormProps((prevFormProps) => ({
            ...prevFormProps,
            isMultipleFundingSourcesEnabled: isMarketingEvent(engagement.type),
          }));

          let addedFundingSources = [];
          let fundingSources = [];

          if (engagement.fundingSources?.length > 0) {
            fundingSources = engagement.fundingSources;

            fundingSources.forEach((item) => {
              addedFundingSources.push({
                businessUnit: businessUnits.find(
                  (businessUnit) => businessUnit.name === item.businessUnit
                ),
                productUnit: productUnits.find(
                  (productUnit) => productUnit.name === item.productUnit
                ),
                region: regions.find((region) => region.name === item.region),
                subRegion: subRegions.find(
                  (subRegion) => subRegion.name === item.subRegion
                ),
                percentage: item.percentage,
              });
            });

            setAddedFundingSources(addedFundingSources);
            setOriginalFundingSources(
              addedFundingSources.map((item) => {
                return { ...item };
              })
            ); // Deep clone the array to save the original list

            if (!isMarketingEvent(engagement.type)) {
              let fundingSource = fundingSources[0];
              let businessUnit = businessUnits.find(
                (businessUnit) =>
                  businessUnit.name === fundingSource.businessUnit
              );
              let productUnit = productUnits.find(
                (productUnit) => productUnit.name === fundingSource.productUnit
              );
              let salesSubRegion = subRegions.find(
                (subRegion) => subRegion.name === fundingSource.subRegion
              );

              // These data are not set for originalEngagementData, since these (businessUnit, productUnit, salesSubRegion) will be compared using originalFundingSources
              setEngagementData((prevEngagementData) => ({
                ...prevEngagementData,
                businessUnit: businessUnit,
                productUnit: productUnit,
                salesSubRegion: salesSubRegion,
              }));
            }
          }

          let countryOption = "";
          let allocationTypeName = "";

          if (engagement.country) {
            countries.forEach((countryItem) => {
              if (countryItem.includes(engagement.country)) {
                countryOption = countryItem;
              }
            });
          }

          if (
            allAllocationTypes &&
            allAllocationTypes.find(
              (type) => type.id === engagement.allocationType
            )
          ) {
            allocationTypeName = allAllocationTypes.find(
              (type) => type.id === engagement.allocationType
            ).allocationTypeName;
          }

          let formattedData = {
            engagementId: engagement.id,
            account: engagement.customerName,
            opportunity: engagement.opportunityName,
            engagementCode: engagement.engagementCode,
            allocationType: allocationTypeName,
            engagementNature: engagement.engagementNature,
            country: countryOption,
            startDate: engagement.startDate,
            endDate: engagement.endDate,
            product: engagement.productName,
            description: engagement.description,
            address: engagement.address,
            customerContact: engagement.customerContact,
            businessDomain: engagement.businessDomain,
            opportunityOwner: engagement.opportunityOwner,
            technicalOwner: engagement.technicalOwner,
            isContractsSigned: engagement.isContractsSigned,
            engagementScope: engagement.engagementScope,
            comment: engagement.comment,
            type: engagement.type,
            productId: engagement.productId,
            allocationTypeId: engagement.allocationType,
            // salesSubRegion: engagement.salesSubRegion
          };

          setEngagementData((prevEngagementData) => ({
            ...prevEngagementData,
            ...formattedData,
          }));

          setOriginalEngagementData((prevEngagementData) => ({
            ...prevEngagementData,
            ...formattedData,
          }));

          setDataOnEngagementType(engagement.type, engagement.productUnit);

          if (isPaidEngagement(engagement.type)) {
            if (!engagement.description) {
              setDescriptionPlaceholder(
                "No additional description in Salesforce"
              );
            } else {
              setDescriptionPlaceholder("");
            }
          }
        }
      });
    }
  }, [allAllocationTypes, businessUnits, productUnits, regions, subRegions]);

  const getAddedEventNamesList = useCallback(async () => {
    const endPointUrl = AppConfig.baseUrl + AppConfig.getAddedEventNamesList;

    handleRequest(
      endPointUrl,
      "GET",
      null,
      (data) => {
        let list = [];

        data.forEach((item) => {
          list.push(item.eventName);
        });

        setAddedEventNamesList(list);
      },
      () => {
        setMessageBar(
          "Error occurred in loading added Event Names",
          true,
          "error"
        );
      }
    );
  }, []);

  useEffect(() => {
    if (isEditMode) {
      setIsSubmitBtnDisabled(true);
    }
    getFundingSources();
  }, [isEditMode]);

  useEffect(() => {
    if (!isEditMode) {
      getEngagementTypes();

      if (!isInternalEngagement) {
        getAllProducts();
      }
    }
  }, [isInternalEngagement]);

  useEffect(() => {
    if (isEditMode) {
      let isUpdated = false;

      const fieldsToCheck = [
        "engagementId",
        "type",
        "account",
        "opportunity",
        "product",
        "description",
        "allocationType",
        "allocationTypeId",
        "engagementCode",
        "engagementNature",
        "country",
        "salesSubRegion",
        "productUnit",
        "businessUnit",
        "address",
        "startDate",
        "endDate",
        "isTentativeDates",
        "customerContact",
        "businessDomain",
        "opportunityOwner",
        "technicalOwner",
        "isContractsSigned",
        "engagementScope",
        "comment",
        "productId",
      ];

      for (let field of fieldsToCheck) {
        if (engagementData[field] !== originalEngagementData[field]) {
          isUpdated = true;
          break;
        }
      }

      if (
        !isUpdated &&
        originalFundingSources.length !== addedFundingSources.length
      ) {
        isUpdated = true;
      } else if (!isUpdated) {
        for (let origItem of originalFundingSources) {
          const existingItem = addedFundingSources.find(
            (fs) =>
              fs.businessUnit?.id === origItem.businessUnit?.id &&
              fs.productUnit?.id === origItem.productUnit?.id &&
              fs.region?.id === origItem.region?.id &&
              fs.subRegion?.id === origItem.subRegion?.id
          );

          if (
            !existingItem ||
            parseFloat(existingItem.percentage) !==
              parseFloat(origItem.percentage)
          ) {
            isUpdated = true;
            break;
          }
        }
      }

      setIsSubmitBtnDisabled(!isUpdated);
    }
  }, [
    isEditMode,
    engagementData,
    originalEngagementData,
    originalFundingSources,
    addedFundingSources,
  ]);

  useEffect(() => {
    if (engagementData.type) {
      const type = engagementData.type;

      if (isEditMode) {
        const updatedProps = getTypeSpecificProps(type);

        setFormProps({
          ...updatedProps,
          ...initialFormProps,
        });

        isOnApprovalProcess
          ? getAllocationTypesForApprovalProcess(type)
          : getAllocationTypes(type);

        if (isInternal(type)) {
          setEngagementData((prevEngagementData) => ({
            ...prevEngagementData,
            account:
              accounts?.find(
                (account) => account.name === FormConstants.Wso2InternalAccount
              ) || prevEngagementData.account,
            engagementNature:
              type === FormConstants.EngagementTypes.InternalEngagement.id
                ? "Off-site"
                : prevEngagementData.engagementNature,
            country:
              type === FormConstants.EngagementTypes.InternalEngagement.id
                ? engagementData.country
                : prevEngagementData.country,
          }));

          if (type === FormConstants.EngagementTypes.MarketingEvent.id) {
            getAddedEventNamesList();
          }
        }

        (isOnApprovalProcess
          ? getAllocationTypesForApprovalProcess(type)
          : getAllocationTypes(type)
        ).then((types) => {
          const selectedType = types?.find(
            (typeObj) => typeObj.id === engagementData.allocationTypeId
          );

          if (selectedType) {
            setEngagementData((prevEngagementData) => ({
              ...prevEngagementData,
              engagementCode:
                selectedType.defaultEngagementCode ||
                prevEngagementData.engagementCode,
            }));
          }
        });
      } else {
        setIsAccountsLoading(false);
        setIsOpportunitiesLoading(false);

        setOpportunities([]);
        setProducts([]);
        setAddedFundingSources(initialFundingSources);

        setEngagementData((prevEngagementData) => ({
          ...prevEngagementData,
          opportunity: "",
          salesSubRegion: "",
        }));

        clearTextElements();
        setIsSubmitBtnClicked(false);
        setShowSalesForceLink(false);

        const updatedProps = getTypeSpecificProps(type);

        setFormProps(updatedProps);

        isOnApprovalProcess
          ? getAllocationTypesForApprovalProcess(type)
          : getAllocationTypes(type);
        // const internalAccount = FormConstants.Wso2InternalAccount;

        if (!isInternal(type) && (accounts.length < 1 || isAccountsLoading)) {
          getAccounts();
        }

        if (isInternal(type)) {
          // accounts.unshift(internalAccount);

          setEngagementData((prevEngagementData) => ({
            ...prevEngagementData,
            account: accounts.find(
              (account) => account.name === FormConstants.Wso2InternalAccount
            ),
            // These are default selected values for Internal engagement type
            engagementNature:
              type === FormConstants.EngagementTypes.InternalEngagement.id
                ? "Off-site"
                : "",
            country:
              type === FormConstants.EngagementTypes.InternalEngagement.id
                ? countries.find((country) => country[1] === "Sri Lanka")
                : "",
          }));

          if (type === FormConstants.EngagementTypes.MarketingEvent.id) {
            getAddedEventNamesList();
          }
        } else {
          // let index = accounts.findIndex(account => account.id === internalAccount.id);
          // if (index > -1) {
          //     accounts.splice(index, 1);
          // }

          setEngagementData((prevEngagementData) => ({
            ...prevEngagementData,
            account: "",
          }));
        }
      }
    }
    // if (engagementData.type === FormConstants.EngagementTypes.MarketingEvent.id) {
    //     onBusinessUnitSelect({ target: { value: 'Corporate', name: 'businessUnit' } })
    // }
  }, [engagementData.type]);

  useEffect(() => {
    if (!isEditMode && engagementData.account) {
      setIsProductsLoading(false);

      if (engagementData.account.name !== FormConstants.Wso2InternalAccount) {
        setEngagementData((prevEngagementData) => ({
          ...prevEngagementData,
          opportunity: "",
        }));

        clearTextElements();
      }

      setIsSubmitBtnClicked(false);
      setShowSalesForceLink(false);

      setOpportunities([]);
      setProducts([]);

      if (
        engagementData.account.id !== undefined &&
        engagementData.account.name !== FormConstants.Wso2InternalAccount
      ) {
        getOpportunities(engagementData.type, engagementData.account.id);

        const selectedAccount = accountsMap[engagementData.account.id];

        onFundingSourceChange(
          "subRegion",
          selectedAccount.salesSubRegionObj,
          0
        );
        onFundingSourceChange(
          "region",
          regions.find(
            (region) =>
              region.id === selectedAccount.salesSubRegionObj?.parentId
          ),
          0
        );

        if (selectedAccount.salesSubRegionObj) {
          setEngagementData((prevEngagementData) => ({
            ...prevEngagementData,
            salesSubRegion: selectedAccount.salesSubRegionObj,
          }));
        } else if (selectedAccount.salesSubRegion) {
          setMessageBar(
            `The "${selectedAccount.salesSubRegion}" Sub Region for this Account is not available in the Allocations App System. Please contact Internal Apps Team.`,
            true,
            "error"
          );
        }
      }
    }
  }, [engagementData.account]);

  useEffect(() => {
    if (!isEditMode && engagementData.opportunity) {
      const opportunity = opportunitiesMap[engagementData.opportunity.id];

      setShowSalesForceLink(false);
      setProducts([]);

      if (opportunity) {
        if (isPaidEngagement(engagementData.type)) {
          getProducts(engagementData.opportunity.id);

          clearTextElements();
          setIsSubmitBtnClicked(false);
        } else if (isNonPaidPostSale(engagementData.type)) {
          getProducts(engagementData.opportunity.id);

          clearTextElements();
          setIsSubmitBtnClicked(false);
        } else {
          setEngagementData((prevEngagementData) => ({
            ...prevEngagementData,
            opportunityOwner: opportunity.opportunityOwner,
            technicalOwner: opportunity.technicalOwner,
          }));

          if (isNonPaidPreSale(engagementData.type)) {
            setEngagementData((prevEngagementData) => ({
              ...prevEngagementData,
              engagementCode: opportunity.preSaleEngagementCode,
            }));
          } else if (isNonPaidPostSale(engagementData.type)) {
            setEngagementCodeForNonPaidPostSale();
          }
        }
      }
    }
  }, [engagementData.opportunity]);

  useEffect(() => {
    if (!isEditMode && engagementData.allocationType) {
      const allocationType = engagementData.allocationType;

      if (isInternal(engagementData.type)) {
        let code = engagementCodeMap[allocationType.id];

        if (engagementCodeSuffixMap[allocationType.id]) {
          code = code + "-" + engagementCodeSuffixMap[allocationType.id];
        }

        setEngagementData((prevEngagementData) => ({
          ...prevEngagementData,
          engagementCode: code,
        }));
      } else if (isNonPaidPostSale(engagementData.type)) {
        setEngagementCodeForNonPaidPostSale();
      }
    }
  }, [engagementData.allocationType]);

  useEffect(() => {
    if (!isEditMode && engagementData.product) {
      const opportunity = opportunitiesMap[engagementData.opportunity.id];
      const product = productsMap[engagementData.product.id];

      setMessageBar("", false);

      if (product) {
        setEngagementData({
          ...engagementData,
          description: product.description,
          engagementCode: product.engagementCodeAuto,
          startDate: product.startDate,
          endDate: product.endDate,
          productUnit: product.productUnitObj,
          businessUnit: product.businessUnitObj,
          isContractsSigned: opportunity.isWon,
          address: opportunity.address,
          opportunityOwner: opportunity.opportunityOwner,
          technicalOwner: opportunity.technicalOwner,
          isTentativeDates: product.isTentativeDates,
        });

        onFundingSourceChange("businessUnit", product.businessUnitObj, 0);
        onFundingSourceChange("productUnit", product.productUnitObj, 0);

        if (
          !product.productUnitObj &&
          !product.businessUnitObj &&
          product.productUnit &&
          product.businessUnit
        ) {
          setMessageBar(
            `The "${product.businessUnit}" Business Unit and "${product.productUnit}" Product Unit for this Opportunity Line Item is not available in the Allocations App System. Please contact Internal Apps Team.`,
            true,
            "error"
          );
        } else if (!product.productUnitObj && product.productUnit) {
          setMessageBar(
            `The "${product.productUnit}" Product Unit for this Opportunity Line Item is not available in the Allocations App System. Please contact Internal Apps Team.`,
            true,
            "error"
          );
        } else if (!product.businessUnitObj && product.businessUnit) {
          setMessageBar(
            `The "${product.businessUnit}" Business Unit for this Opportunity Line Item is not available in the Allocations App System. Please contact Internal Apps Team.`,
            true,
            "error"
          );
        }

        setSalesForceLink(
          AppConfig.salesForceUrl + "/" + engagementData.product.id
        );
        setShowSalesForceLink(true);

        if (!product.description) {
          setDescriptionPlaceholder("No additional description in Salesforce");
        } else {
          setDescriptionPlaceholder("");
        }

        // If there is no BU in SF, we enable the BU dropdown for the user to choose
        if (!product.businessUnit) {
          setFormProps((prevProps) => ({
            ...prevProps,
            isBusinessUnitDisabled: false,
          }));
        } else {
          setFormProps((prevProps) => ({
            ...prevProps,
            isBusinessUnitDisabled: true,
          }));
        }
      }

      if (
        allocationRequestedProducts.indexOf(engagementData.product.id) !== -1 &&
        engagementData.type === 2
      ) {
        setMessageBar(
          "An allocation has already been requested for this product",
          true,
          "error"
        );
        setShowSalesForceLink(false);
      }
    }
  }, [engagementData.product]);

  useEffect(() => {
    let allocationTypeName = "";

    if (
      engagementData.allocationTypeId &&
      allAllocationTypes &&
      allAllocationTypes.find(
        (type) => type.id === engagementData.allocationTypeId
      )
    ) {
      allocationTypeName = allAllocationTypes.find(
        (type) => type.id === engagementData.allocationTypeId
      ).allocationTypeName;
    }

    setEngagementData((prevEngagementData) => ({
      ...prevEngagementData,
      allocationType: allocationTypeName,
    }));
  }, [allAllocationTypes, engagementData.allocationTypeId]);

  const clearTextElements = () => {
    let resetValues = {
      product: "",
      allocationType: "",
      engagementCode: "",
      startDate: null,
      endDate: null,
      description: "",
      engagementScope: "",
      comment: "",
      businessDomain: "",
      opportunityOwner: "",
      technicalOwner: "",
      customerContact: "",
      isContractsSigned: false,
      isTentativeDates: false,
      // salesSubRegion: "",
      productUnit: "",
      businessUnit: "",
      address: "",
      engagementNature: "",
      country: "",
    };

    setEngagementData((prevEngagementData) => ({
      ...prevEngagementData,
      ...resetValues,
    }));

    setDescriptionPlaceholder("");
  };

  const setEngagementCodeForNonPaidPostSale = () => {
    if (
      engagementData.opportunity &&
      engagementData.allocationType &&
      engagementData.allocationType.id
    ) {
      if (engagementCodeSuffixMap[engagementData.allocationType.id]) {
        let engagementCodePrefix = opportunitiesMap[
          engagementData.opportunity.id
        ].postSaleEngagementCode
          .toString()
          .split("-");
        let engagementCode =
          engagementCodePrefix[0] +
          "-" +
          engagementCodeSuffixMap[engagementData.allocationType.id];

        setEngagementData({
          ...engagementData,
          engagementCode: engagementCode,
        });
      } else {
        setEngagementData({
          ...engagementData,
          engagementCode: "",
        });
        setMessageBar(
          "Engagement Code cannot be set. Engagement Code Suffix is not available for the selected Allocation Type.",
          true,
          "error"
        );
      }
    }
  };

  // Used to validate each required field and add an error in each if not valid
  const isFieldValueValid = (field) => {
    // Check if isSubmitBtnClicked is true and needed value is available for the field
    return !(isSubmitBtnClicked && !engagementData[field]);
  };

  const isUserInputInvalid = (value) => {
    return isSubmitBtnClicked && !validateUserInput(value);
  };

  // Validate form as a whole
  const getFormValidation = () => {
    let isValid = true;
    // let nonEmptyFundingSources = [];
    // let isPercValid = true;

    let startDate = getDateObjectFromString(engagementData.startDate);
    let endDate = getDateObjectFromString(engagementData.endDate);

    if (isNaN(startDate) || isNaN(endDate)) {
      setMessageBar("Start/End Dates should be valid dates", true, "error");
      return false;
    }

    const requiredFields = [
      "type",
      "opportunity",
      "allocationType",
      "engagementCode",
      "engagementNature",
      "country",
      ...(engagementData.type ===
      FormConstants.EngagementTypes.MarketingEvent.id
        ? []
        : ["businessUnit", "productUnit", "salesSubRegion"]),
      "address",
      "startDate",
      "endDate",
    ];

    if (
      isMarketingEvent(engagementData.type) &&
      addedFundingSources.some((item) =>
        Object.values(item).some((field) => !field)
      )
    ) {
      isValid = false;
    }

    if (!isInternal(engagementData.type)) {
      requiredFields.push("customerContact");
      requiredFields.push("account");
    }

    if (isPaidEngagement(engagementData.type)) {
      requiredFields.push("product");
    }

    for (let field of requiredFields) {
      // requiredFields.forEach(field => {
      if (!engagementData[field]) {
        isValid = false;
        break;
      }
      // });
    }

    if (!isValid) {
      setMessageBar("You need to fill the required fields", true, "error");
      return false;
    }

    const inputValidationFields = ["address", "engagementScope", "comment"];

    if (!isEditMode && isInternal(engagementData.type)) {
      inputValidationFields.push("opportunity");
    }

    if (!isInternal(engagementData.type)) {
      inputValidationFields.push("customerContact");
    }

    if (!isEditMode) {
      inputValidationFields.push("description");
    }

    for (let field of inputValidationFields) {
      if (engagementData[field] && !validateUserInput(engagementData[field])) {
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      setMessageBar(INPUT_INVALID_MSG, true, "error");
      return false;
    }

    if (
      engagementData.type === FormConstants.EngagementTypes.MarketingEvent.id &&
      addedEventNamesList.includes(
        (item) =>
          item.trim().toLowerCase() ===
          engagementData.opportunity.trim().toLowerCase()
      )
    ) {
      setMessageBar("Event name already exists!", true, "error");
      return false;
    }

    if (isMarketingEvent(engagementData.type)) {
      if (
        addedFundingSources.some(
          (item) => item.percentage === 0 || item.percentage === "0"
        )
      ) {
        setMessageBar(
          "Funding source percentage cannot be zero",
          true,
          "error"
        );
        return false;
      } else if (
        addedFundingSources.reduce(
          (accumulator, item) =>
            accumulator + parseInt(item.percentage ? item.percentage : 0),
          0
        ) !== 100
      ) {
        setMessageBar(
          "Funding source percentages sum should be 100",
          true,
          "error"
        );
        return false;
      }
      let uniqueAddedFundingSourcesList = [
        ...new Set(
          addedFundingSources.map((item) =>
            [
              item.businessUnit?.id,
              item.productUnit?.id,
              item.region?.id,
              item.subRegion?.id,
            ].join("-")
          )
        ),
      ];

      if (addedFundingSources.length !== uniqueAddedFundingSourcesList.length) {
        setMessageBar("Funding sources have duplicate records", true, "error");
        return false;
      }
    }

    if (
      !isEditMode &&
      (isPastDateForEng(startDate, isInternal(engagementData.type)) ||
        isPastDateForEng(endDate, isInternal(engagementData.type)))
    ) {
      setMessageBar(
        `Dates cannot be less than ${
          isInternal(engagementData.type)
            ? ALLOWED_PAST_DAYS_ENG_INTERNAL
            : ALLOWED_PAST_DAYS_ENG
        } days from today`,
        true,
        "error"
      );
      return false;
    }

    if (
      isEditMode &&
      ((startDate.getTime() !==
        getDateObjectFromString(originalEngagementData.startDate).getTime() &&
        isPastDateForEng(startDate, isInternal(engagementData.type))) ||
        (endDate.getTime() !==
          getDateObjectFromString(originalEngagementData.endDate).getTime() &&
          isPastDateForEng(endDate, isInternal(engagementData.type))))
    ) {
      setMessageBar(
        `Selected dates cannot be less than ${
          isInternal(engagementData.type)
            ? ALLOWED_PAST_DAYS_ENG_INTERNAL
            : ALLOWED_PAST_DAYS_ENG
        } days from today`,
        true,
        "error"
      );
      return false;
    }

    if (startDate > endDate) {
      setMessageBar(
        "Start Date should be less than or equal to End Date",
        true,
        "error"
      );
      return false;
    }

    if (
      !isEditMode &&
      engagementData.product &&
      allocationRequestedProducts.indexOf(engagementData.product.id) !== -1 &&
      engagementData.type === 2
    ) {
      setMessageBar(
        "An allocation has already been requested for this product",
        true,
        "error"
      );
      return false;
    }

    if (engagementData.type === "Non-Paid" && engagementData.isTentativeDates) {
      // This is not used
      setMessageBar(
        "You are not allowed to create an Engagement with tentative start and end dates",
        true,
        "error"
      );
      return false;
    } else if (
      engagementData.type === "Paid" &&
      engagementData.isTentativeDates
    ) {
      setMessageBar(
        "You are not allowed to create an Engagement with tentative start and end dates, Please update Salesforce with fixed dates and come back..",
        true,
        "error"
      );
      return false;
    } else {
      return true;
    }
  };

  const handleDialogBoxAction = (isConfirm) => {
    switch (confirmDialogDetails.id) {
      case "submitEngagement":
        return handleSubmitEngagement(isConfirm);
      case "setInternalAllocations":
        return handleInternalAllocationsPopup(isConfirm);
      case "updateEngagement":
        return handleUpdateEngagement(isConfirm);
      case "approveEngagement":
        return handleApproveEngagement(isConfirm);
      default:
        return;
    }
  };

  const getConfirmDialogDetails = () => {
    return isOnApprovalProcess &&
      isAccessGranted([
        Privileges.APPROVE_INTERNAL_ENGAGEMENTS_APPROVE ||
          Privileges.APPROVE_MARKETING_EVENTS_APPROVE,
      ])
      ? ENGAGEMENTS.APPROVE_ENGAGEMENTS.MESSAGES.CONFIRM_APPROVE
      : ENGAGEMENTS.NEW_ENGAGEMENT.MESSAGES.CONFIRM_UPDATE;
  };

  // isOpen is redundant for now.
  const onClickSubmitBtn = (isOpen) => {
    setIsSubmitBtnClicked(true);
    let isFormValidated = getFormValidation();
    // let isFormValidated = true;

    if (isFormValidated) {
      if (isOpen) {
        if (isEditMode) {
          let confirmDialogDetails = getConfirmDialogDetails();

          dispatchForm({
            type: isOnApprovalProcess
              ? ACTIONS.APPROVE_ENGAGEMENT
              : ACTIONS.UPDATE_ENGAGEMENT,
            confirmDialogDetails: {
              open: true,
              id: confirmDialogDetails.id,
              title: confirmDialogDetails.title,
              message: confirmDialogDetails.message,
            },
          });
        } else {
          let confirmDialogDetails =
            ENGAGEMENTS.NEW_ENGAGEMENT.MESSAGES.CONFIRM_SUBMIT;

          dispatchForm({
            type: ACTIONS.SUBMIT_ENGAGEMENT,
            confirmDialogDetails: {
              open: true,
              id: confirmDialogDetails.id,
              title:
                engagementData.type ===
                FormConstants.EngagementTypes.MarketingEvent.id
                  ? confirmDialogDetails.titleEvent
                  : confirmDialogDetails.title,
              message:
                engagementData.type ===
                FormConstants.EngagementTypes.MarketingEvent.id
                  ? confirmDialogDetails.messageEvent
                  : confirmDialogDetails.message,
            },
          });
        }
      } else {
        dispatchForm({
          type: ACTIONS.SUBMIT_ENGAGEMENT,
          confirmDialogDetails: {},
        });
      }
    }
  };

  const onClickCancelBtn = () => {
    if (isEditMode) {
      if (isOnApprovalProcess) {
        history.push({
          pathname: PAGES.APPROVE_INTERNAL_VIEW,
          state: { tab: tabIndex },
        });
      } else {
        history.push({
          pathname: isInternalEngagement
            ? PAGES.INTERNAL
            : PAGES.NEW_ENGAGEMENT,
          state: { tab: tabIndex },
        });
      }
    }

    if (setTab) {
      setTab(1);
    }
  };

  const onCloseModalPopup = () => {
    if (setTab) {
      setTab(tabIndex);
    }
  };

  const handleSubmitEngagement = (isConfirm) => {
    dispatchForm({
      type: ACTIONS.SUBMIT_ENGAGEMENT,
      confirmDialogDetails: {},
    });

    if (isConfirm) {
      submitEngagement(engagementData);
    }
  };

  const handleUpdateEngagement = (isConfirm) => {
    dispatchForm({
      type: ACTIONS.UPDATE_ENGAGEMENT,
      confirmDialogDetails: {},
    });

    if (isConfirm) {
      updateEngagement(engagementData);
    }
  };

  const handleApproveEngagement = (isConfirm) => {
    dispatchForm({
      type: ACTIONS.APPROVE_ENGAGEMENT,
      confirmDialogDetails: {},
    });

    if (isConfirm) {
      approveEngagement(engagementData);
    }
  };

  const submitEngagement = useCallback(
    async (engagementData) => {
      setIsSubmitBtnDisabled(true);

      let isInternalEngagement = isInternal(engagementData.type);
      let endPointUrl = AppConfig.baseUrl + AppConfig.addNewEngagement;
      let fundingSources = addedFundingSources.map((item) => ({
        businessUnit: item.businessUnit?.name,
        productUnit: item.productUnit?.name,
        region: item.region?.name,
        subRegion: item.subRegion?.name,
        percentage: parseFloat(item.percentage),
      }));

      let uuid = generateUUID();
      let formattedEngagementData = {
        allocationType: engagementData.allocationType.id
          ? engagementData.allocationType.id.toString()
          : "",
        ...(isInternalEngagement
          ? {}
          : {
              customerName: engagementData.account.name,
              customerCode: engagementData.account.id,
            }),
        address: engagementData.address,
        technicalContact: "",
        opportunitySize: "",
        businessDomain: engagementData.businessDomain,
        additionalMaterial: "",
        isContractsSigned: engagementData.isContractsSigned,
        engagementCode: engagementData.engagementCode,
        startDate: engagementData.startDate,
        endDate: engagementData.endDate,
        status: isOnApprovalProcess
          ? RequestStatus.PENDING_APPROVAL
          : RequestStatus.NEW,
        opportunityName: engagementData.opportunity
          ? isInternalEngagement
            ? engagementData.opportunity
            : engagementData.opportunity.name
          : "",
        opportunityId:
          engagementData.opportunity && engagementData.opportunity.id
            ? engagementData.opportunity.id
            : "",
        productId: engagementData.product ? engagementData.product.id : "",
        opportunityOwner: engagementData.opportunityOwner,
        // lastUpdatedDate: "",
        productName: engagementData.product ? engagementData.product.name : "",
        engagementScope: engagementData.engagementScope,
        engagementStatus: "Pending",
        isTentativeDates: engagementData.isTentativeDates,
        engagementNature: engagementData.engagementNature,
        customerContact: engagementData.customerContact,
        technicalOwner: engagementData.technicalOwner,
        comment: engagementData.comment,
        country: engagementData.country[1],
        uuid: uuid,
        description: engagementData.description
          ? engagementData.description
          : "",
        fundingSources: fundingSources,
      };

      handleRequest(
        endPointUrl,
        "POST",
        formattedEngagementData,
        (data) => {
          setEngagementData({
            ...engagementData,
            engagementId: data?.id,
          });

          setMessageBar(
            engagementData.type ===
              FormConstants.EngagementTypes.MarketingEvent.id
              ? "New Event was successfully added"
              : "New Engagement was successfully added",
            true,
            "success"
          );

          history.push({
            pathname:
              engagementData.type ===
                FormConstants.EngagementTypes.MarketingEvent.id ||
              engagementData.type ===
                FormConstants.EngagementTypes.InternalEngagement.id
                ? PAGES.APPROVE_INTERNAL_VIEW
                : PAGES.NEW_ENGAGEMENT,
            state: { tab: tabIndex },
          });
        },

        (customErrMsg) => {
          setMessageBar(
            customErrMsg
              ? customErrMsg
              : "An error occurred in Engagement submission",
            true,
            "error"
          );
          setIsSubmitBtnDisabled(false);
        },
        setIsLoading
      );
    },
    [addedFundingSources, allocationTypes]
  );

  const updateEngagement = useCallback(
    async (engagementData) => {
      setIsSubmitBtnDisabled(true);
      let endPointUrl =
        AppConfig.baseUrl +
        AppConfig.updateEngagement.replace("$id", engagementData.engagementId);
      let fundingSources = addedFundingSources.map((item) => ({
        businessUnit: item.businessUnit?.name,
        productUnit: item.productUnit?.name,
        region: item.region?.name,
        subRegion: item.subRegion?.name,
        percentage: parseFloat(item.percentage),
      }));

      let formattedEngagementData = {
        // Old code comment: if we update the last_updated_date there will be an issue in SF record timestamp. we might need to introduce a new field
        // lastUpdatedDate: "",
        status: isOnApprovalProcess
          ? RequestStatus.PENDING_APPROVAL
          : RequestStatus.UPDATED,
        startDate: getDateFromString(engagementData.startDate),
        endDate: getDateFromString(engagementData.endDate),
        address: engagementData.address,
        engagementScope: engagementData.engagementScope,
        comment: engagementData.comment,
        engagementNature: engagementData.engagementNature,
        country: engagementData.country[1],
        customerContact: engagementData.customerContact,
        fundingSources: fundingSources,
        allocationType: engagementData.allocationType.id,
        allocationTypeName: engagementData.allocationType.allocationTypeName,
        engagementCode: engagementData.engagementCode,
      };

      handleRequest(
        endPointUrl,
        "PATCH",
        formattedEngagementData,
        (data) => {
          setMessageBar("Engagement was successfully updated", true, "success");

          onClickCancelBtn();
        },
        (customErrMsg) => {
          setMessageBar(
            customErrMsg
              ? customErrMsg
              : "An error occurred in Engagement update",
            true,
            "error"
          );
          setIsSubmitBtnDisabled(false);
        },
        setIsLoading
      );
    },
    [addedFundingSources, allAllocationTypes]
  );

  const approveEngagement = useCallback(
    async (engagementData) => {
      setIsSubmitBtnDisabled(true);

      let endPointUrl =
        AppConfig.baseUrl +
        AppConfig.updateEngagement.replace("$id", engagementData.engagementId);
      let fundingSources = addedFundingSources.map((item) => ({
        businessUnit: item.businessUnit?.name,
        productUnit: item.productUnit?.name,
        region: item.region?.name,
        subRegion: item.subRegion?.name,
        percentage: parseFloat(item.percentage),
      }));

      let updatedFields = {
        status: RequestStatus.APPROVED,
      };

      if (
        getDateFromString(originalEngagementData.startDate) !==
        engagementData.startDate
      ) {
        updatedFields.startDate = getDateFromString(engagementData.startDate);
      }
      if (
        getDateFromString(originalEngagementData.endDate) !==
        engagementData.endDate
      ) {
        updatedFields.endDate = getDateFromString(engagementData.endDate);
      }
      if (originalEngagementData.address !== engagementData.address) {
        updatedFields.address = engagementData.address;
      }
      if (
        originalEngagementData.engagementScope !==
        engagementData.engagementScope
      ) {
        updatedFields.engagementScope = engagementData.engagementScope;
      }
      if (originalEngagementData.comment !== engagementData.comment) {
        updatedFields.comment = engagementData.comment;
      }
      if (
        originalEngagementData.engagementNature !==
        engagementData.engagementNature
      ) {
        updatedFields.engagementNature = engagementData.engagementNature;
      }
      if (originalEngagementData.country[1] !== engagementData.country[1]) {
        updatedFields.country = engagementData.country[1];
      }
      if (
        originalEngagementData.customerContact !==
        engagementData.customerContact
      ) {
        updatedFields.customerContact = engagementData.customerContact;
      }
      if (
        JSON.stringify(originalFundingSources) !==
        JSON.stringify(fundingSources)
      ) {
        updatedFields.fundingSources = fundingSources;
      }
      if (
        originalEngagementData.allocationType.id !==
        engagementData.allocationType.id
      ) {
        updatedFields.allocationType = engagementData.allocationType.id;
        updatedFields.allocationTypeName =
          engagementData.allocationType.allocationTypeName;
      }
      if (
        originalEngagementData.engagementCode !== engagementData.engagementCode
      ) {
        updatedFields.engagementCode = engagementData.engagementCode;
      }

      handleRequest(
        endPointUrl,
        "PATCH",
        updatedFields,
        (data) => {
          setMessageBar(
            "Engagement was successfully approved",
            true,
            "success"
          );

          onClickCancelBtn();
        },
        (customErrMsg) => {
          setMessageBar(
            customErrMsg
              ? customErrMsg
              : "An error occurred in Approval Process",
            true,
            "error"
          );
          setIsSubmitBtnDisabled(false);
        },
        setIsLoading
      );
    },
    [
      addedFundingSources,
      allAllocationTypes,
      originalEngagementData,
      originalFundingSources,
    ]
  );

  const handleInternalAllocationsPopup = (isConfirm) => {
    dispatchForm({
      type: ACTIONS.SET_INTERNAL_ALLOCATIONS,
      confirmDialogDetails: {},
    });

    // If isConfirm is false, Internal Allocations Popup will not be open.
    if (isConfirm) {
      let minStartDate = getDateObjectFromString(engagementData.startDate);
      let maxEndDate = getDateObjectFromString(engagementData.endDate);
      let startDate = getDateObjectFromString(engagementData.startDate);
      let endDate = getDateObjectFromString(engagementData.endDate);

      minStartDate.setDate(
        minStartDate.getDate() - PRE_ENGAGEMENT_RELAX_PERIOD_DAYS
      );
      maxEndDate.setDate(
        maxEndDate.getDate() + POST_ENGAGEMENT_RELAX_PERIOD_DAYS
      );

      dispatchForm({
        type: ACTIONS.OPEN_INTERNAL_ALLOCATIONS_POPUP,
        allocationsPopupDetails: {
          open: true,
          startDate: startDate,
          endDate: endDate,
          allocationTypeObj: engagementData.allocationType,
          engagementCode: engagementData.engagementCode,
          engagementId: engagementData.engagementId,
          allocationType: engagementData.allocationType
            ? engagementData.allocationType.allocationType
            : "",

          minStartDate: isPastDateForAlloc(new Date(minStartDate))
            ? getAllowedPastDateForAlloc()
            : new Date(minStartDate),
          maxStartDate: endDate,
          minEndDate: isPastDateForAlloc(new Date(startDate))
            ? getAllowedPastDateForAlloc()
            : startDate,
          maxEndDate: new Date(maxEndDate),

          startTime: null,
          endTime: null,

          engagementTypeId: engagementData.type,
          country: engagementData.country[1],
        },
      });
    } else {
      setTab(tabIndex);
    }
  };

  const handleInternalAllocationsSubmission = (isConfirm) => {
    // Closes the Internal Allocations Popup
    dispatchForm({
      type: ACTIONS.OPEN_INTERNAL_ALLOCATIONS_POPUP,
      allocationsPopupDetails: {},
    });

    if (isConfirm) {
    }
  };

  const onClickSFetchNewDates = () => {
    getNewDates(engagementData);
  };

  const handleSubmitBtnStatus = (name, newValue) => {
    if (isEditMode) {
      if (name && originalEngagementData[name] !== newValue) {
        setIsSubmitBtnDisabled(false);
      } else {
        let btnDisabled = true;

        // Business Unit and Product Unit are validated in Funding Sources validation
        let editableFieldsList = [
          "engagementNature",
          "country",
          "address",
          "startDate",
          "endDate",
          "customerContact",
          "engagementScope",
          "comment",
        ];

        editableFieldsList.splice(editableFieldsList.indexOf(name), 1);

        for (let field of editableFieldsList) {
          if (originalEngagementData[field] !== engagementData[field]) {
            btnDisabled = false;
            break;
          }
        }

        setIsSubmitBtnDisabled(btnDisabled);
      }
    }
  };

  const onFundingSourceChange = (name, value, index) => {
    const list = [...addedFundingSources];
    list[index][name] = value;

    setAddedFundingSources(list);
  };

  const onBusinessUnitSelect = (e) => {
    handleInputChange(e);

    onFundingSourceChange("businessUnit", e.target.value, 0);

    let productUnit = addedFundingSources?.[0]?.productUnit;

    if (
      productUnit &&
      productUnit.parentId &&
      productUnit.parentId !== e.target.value?.id
    ) {
      setEngagementData((prevEngagementData) => ({
        ...prevEngagementData,
        productUnit: null,
      }));
      onFundingSourceChange("productUnit", null, 0);
    }
  };

  const onProductUnitSelect = (e) => {
    handleInputChange(e);
    onFundingSourceChange("productUnit", e.target.value, 0);

    let businessUnit = businessUnits.find(
      (businessUnit) => businessUnit.id === e.target.value?.parentId
    );

    if (businessUnit) {
      setEngagementData((prevEngagementData) => ({
        ...prevEngagementData,
        businessUnit: businessUnit,
      }));
      onFundingSourceChange("businessUnit", businessUnit, 0);
    }
  };

  const onSubRegionSelect = (e) => {
    handleInputChange(e);
    onFundingSourceChange("subRegion", e.target.value, 0);
    onFundingSourceChange(
      "region",
      regions.find((region) => region.id === e.target.value.parentId),
      0
    );
  };

  const handleInputChange = (e) => {
    setEngagementData((prevEngagementData) => ({
      ...prevEngagementData,
      [e.target.name]: e.target.value,
    }));

    handleSubmitBtnStatus(e.target.name, e.target.value);
  };

  const handleStartDateChange = (date) => {
    if (date) {
      setEngagementData({
        ...engagementData,
        startDate: formatDateWithDashesToInput(date),
      });

      handleSubmitBtnStatus("startDate", date);
    } else {
      setEngagementData({
        ...engagementData,
        startDate: null,
      });
    }
  };

  const handleEndDateChange = (date) => {
    if (date) {
      setEngagementData({
        ...engagementData,
        endDate: formatDateWithDashesToInput(date),
      });

      handleSubmitBtnStatus("endDate", date);
    } else {
      setEngagementData({
        ...engagementData,
        endDate: null,
      });
    }
  };

  const onAccountChange = (event, value) => {
    if (value) {
      setEngagementData({
        ...engagementData,
        account: value,
        // salesSubRegion: selectedAccount.salesSubRegion
      });
    }
  };

  const onAllocationTypeChange = (event, newValue) => {
    if (newValue) {
      setEngagementData((prevData) => ({
        ...prevData,
        allocationType: newValue,
        engagementCode:
          newValue.defaultEngagementCode || prevData.engagementCode,
      }));
    }
  };

  const onCountryChange = (event, value) => {
    if (value) {
      setEngagementData({
        ...engagementData,
        country: value,
      });
    }

    handleSubmitBtnStatus("country", value);
  };

  const handleCheckboxChange = (event) => {
    setEngagementData({
      ...engagementData,
      [event.target.name]: event.target.checked,
    });
  };

  const isDateError = (dateName, date) => {
    let dateObj = date ? getDateObjectFromString(date) : null;

    if (
      isNaN(dateObj) ||
      !isFieldValueValid(dateName) ||
      (!isEditMode &&
        isPastDateForEng(dateObj, isInternal(engagementData.type))) ||
      (isEditMode &&
        originalEngagementData[dateName] &&
        getDateObjectFromString(originalEngagementData[dateName]).getTime() !==
          dateObj.getTime() &&
        isPastDateForEng(dateObj, isInternal(engagementData.type)))
    ) {
      return true;
    }

    return false;
  };

  return (
    <React.Fragment>
      {/* <Snackbar open={isMessageBarOpen} autoHideDuration={6000} onClose={handleMessageBarClose}>
                <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
                    {alertMessage}
                </Alert>
            </Snackbar> */}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" display="block" gutterBottom>
            {formTitle}
          </Typography>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>

      {isEditMode && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography variant="subtitle1" align="left" className={classes.root}>
            {engagementData.type ===
            FormConstants.EngagementTypes.MarketingEvent.id
              ? "Event Id"
              : "Engagement Id"}
          </Typography>
          <FormControl className={classes.formControl}>
            <TextField
              disabled
              id="filled-disabled"
              label={
                engagementData.type ===
                FormConstants.EngagementTypes.MarketingEvent.id
                  ? "Event Id"
                  : "Engagement Id"
              }
              variant="outlined"
              value={engagementData.engagementId}
            />
          </FormControl>
        </Grid>
      )}

      {!isEditMode && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography variant="subtitle1" align="left" className={classes.root}>
            Engagement Type
          </Typography>
          <FormControl
            required
            className={classes.formControl}
            variant="outlined"
            error={!isFieldValueValid("type")}
          >
            <InputLabel id="demo-simple-select-helper-label">
              Select Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={engagementData.type}
              name="type"
              onChange={handleInputChange}
              label="Select Type"
            >
              {engagementTypes && engagementTypes.length > 0 ? (
                engagementTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  No options
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      )}
      {engagementData?.type && typeof engagementData.type === "number" && (
        <>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {!isEditMode ? (
              !isInternal(engagementData.type) && (
                <Fragment>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    className={classes.root}
                  >
                    Account
                  </Typography>

                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      options={
                        isInternal(engagementData.type)
                          ? accounts.filter(
                              (account) =>
                                account.name ===
                                FormConstants.Wso2InternalAccount
                            )
                          : accounts
                              .filter(
                                (account) =>
                                  account.name !==
                                  FormConstants.Wso2InternalAccount
                              )
                              .sort((a, b) => -b.name.localeCompare(a.name))
                      }
                      getOptionLabel={(option) => option.name}
                      disabled={formProps.isAccountDisabled}
                      value={engagementData.account}
                      id="account"
                      onChange={onAccountChange}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          error={!isFieldValueValid("account")}
                          label="Select an Account"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                  <CircularProgressIndeterminate
                    isLoading={isAccountsLoading}
                  />
                </Fragment>
              )
            ) : (
              <Fragment>
                <Typography
                  variant="subtitle1"
                  align="left"
                  className={classes.root}
                >
                  Account
                </Typography>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="outlined-textarea"
                    label="Account"
                    variant="outlined"
                    disabled={formProps.isAccountDisabled}
                    value={engagementData.account}
                  />
                </FormControl>
              </Fragment>
            )}
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.root}
            >
              {isInternal(engagementData.type)
                ? engagementData.type ===
                  FormConstants.EngagementTypes.MarketingEvent.id
                  ? "Event Name"
                  : "Name / Purpose"
                : "Opportunity Name"}
            </Typography>

            {formProps.isOpportunitySelectAvail ? (
              <React.Fragment>
                <FormControl
                  required
                  className={classes.formControl}
                  variant="outlined"
                  error={!isFieldValueValid("opportunity")}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Select Opportunity
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={engagementData.opportunity}
                    name="opportunity"
                    disabled={isEditMode}
                    onChange={handleInputChange}
                    label={
                      engagementData.type ===
                      FormConstants.EngagementTypes.MarketingEvent.id
                        ? "Event Name"
                        : "Opportunity Name"
                    }
                  >
                    {opportunities && opportunities.length > 0 ? (
                      opportunities.map((opportunity) => (
                        <MenuItem key={opportunity.id} value={opportunity}>
                          {opportunity.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" disabled>
                        No options
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
                <CircularProgressIndeterminate
                  isLoading={isOpportunitiesLoading}
                />
              </React.Fragment>
            ) : (
              <FormControl className={classes.formControl}>
                <TextField
                  id="outlined-text-oppor"
                  label={
                    isInternal(engagementData.type)
                      ? engagementData.type ===
                        FormConstants.EngagementTypes.MarketingEvent.id
                        ? "Event Name"
                        : "Name / Purpose"
                      : "Opportunity Name"
                  }
                  variant="outlined"
                  name="opportunity"
                  disabled={isEditMode}
                  value={engagementData.opportunity}
                  onChange={handleInputChange}
                  error={
                    !isFieldValueValid("opportunity") ||
                    isUserInputInvalid(engagementData.opportunity)
                  }
                  required
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>
            )}
          </Grid>

          {formProps.isProductAvail && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.root}
              >
                Opportunity Line Item
              </Typography>

              {!isEditMode ? (
                <Fragment>
                  <FormControl
                    required
                    className={classes.formControl}
                    variant="outlined"
                    error={!isFieldValueValid("product")}
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Select Opportunity Line Item
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={engagementData.product}
                      name="product"
                      disabled={isEditMode}
                      onChange={handleInputChange}
                      label="Select Opportunity Line Item"
                    >
                      {products && products.length > 0 ? (
                        products.map((product) => (
                          <MenuItem key={product.id} value={product}>
                            {product.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="" disabled>
                          No options
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  <CircularProgressIndeterminate
                    isLoading={isProductsLoading}
                  />
                  {showSalesForceLink && (
                    <Typography>
                      <Link
                        href={salesForceLink}
                        target="_blank"
                        color="secondary"
                      >
                        Go to salesforce page
                      </Link>
                    </Typography>
                  )}
                </Fragment>
              ) : (
                <FormControl className={classes.formControl}>
                  <TextField
                    id="outlined-textarea"
                    label="Product"
                    variant="outlined"
                    disabled={isEditMode}
                    value={engagementData.product}
                  />
                </FormControl>
              )}
            </Grid>
          )}

          {formProps.isDescriptionAvail && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.root}
              >
                Description
              </Typography>
              <FormControl className={classes.formControl}>
                <TextField
                  id="outlined-textarea"
                  label={
                    descriptionPlaceholder
                      ? descriptionPlaceholder
                      : "Any additional information"
                  }
                  disabled={formProps.isDescriptionDisabled}
                  multiline
                  rows={4}
                  variant="outlined"
                  name="description"
                  onChange={handleInputChange}
                  value={engagementData.description}
                  error={isUserInputInvalid(engagementData.description)}
                />
              </FormControl>
            </Grid>
          )}

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.root}
            >
              {engagementData.type ===
              FormConstants.EngagementTypes.MarketingEvent.id
                ? "Event Type"
                : "Allocation Type"}
            </Typography>

            {!isEditMode ? (
              <FormControl className={classes.formControl}>
                <Autocomplete
                  options={allocationTypes.filter(
                    (type) => type.engagementTypeId === engagementData.type
                  )}
                  // options={allocationTypes.filter(type => (type.engagementTypeId))}
                  getOptionLabel={(option) => option.allocationTypeName}
                  id="alloc-type"
                  value={engagementData.allocationType}
                  disabled={isEditMode}
                  onChange={onAllocationTypeChange}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      error={!isFieldValueValid("allocationType")}
                      label={
                        engagementData.type ===
                        FormConstants.EngagementTypes.MarketingEvent.id
                          ? "Select Event Type"
                          : "Select Allocation Type"
                      }
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            ) : (
              <FormControl className={classes.formControl}>
                <Autocomplete
                  options={allocationTypes.filter(
                    (type) => type.engagementTypeId === engagementData.type
                  )}
                  getOptionLabel={(option) => option.allocationTypeName}
                  id="alloc-type"
                  value={
                    engagementData.allocationType
                      ? allocationTypes.find(
                          (type) => type.id === engagementData.allocationType.id
                        )
                      : null
                  }
                  disabled={formProps.isAllocationTypeDisabled}
                  onChange={(event, newValue) => {
                    onAllocationTypeChange(event, newValue);
                  }}
                  renderInput={(params) => {
                    const selectedValue = allocationTypes.find(
                      (type) => type.id === engagementData.allocationTypeId
                    );
                    return (
                      <TextField
                        {...params}
                        error={!isFieldValueValid("allocationType")}
                        label={
                          selectedValue
                            ? selectedValue.allocationTypeName
                            : engagementData.type ===
                                FormConstants.EngagementTypes.MarketingEvent.id
                              ? "Select Event Type"
                              : "Select Allocation Type"
                        }
                        variant="outlined"
                        required
                      />
                    );
                  }}
                />
              </FormControl>
            )}
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.root}
            >
              Engagement Code
            </Typography>
            <FormControl
              className={classes.formControl}
              error={!isFieldValueValid("engagementCode")}
            >
              <TextField
                disabled
                required
                id="filled-disabled"
                label="Engagement Code"
                variant="outlined"
                value={engagementData.engagementCode}
                error={!isFieldValueValid("engagementCode")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.root}
            >
              {isInternal(engagementData.type)
                ? engagementData.type ===
                  FormConstants.EngagementTypes.MarketingEvent.id
                  ? "Event Nature"
                  : "On-site/Off-site"
                : "Engagement On-site/Off-site"}
            </Typography>
            <FormControl
              required
              className={classes.formControl}
              variant="outlined"
              error={!isFieldValueValid("engagementNature")}
            >
              <InputLabel id="demo-simple-select-helper-label">
                Select Location
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={engagementData.engagementNature}
                name="engagementNature"
                onChange={handleInputChange}
                label="Select Location"
              >
                {engagementData.type ===
                FormConstants.EngagementTypes.MarketingEvent.id
                  ? eventNatureOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))
                  : engagementNatureOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.root}
            >
              {isInternal(engagementData.type)
                ? "Country"
                : "Engagement Country"}
            </Typography>
            <FormControl className={classes.formControl}>
              <Autocomplete
                options={countries}
                getOptionLabel={(option) => option[1]}
                id="eng-country"
                value={engagementData.country}
                onChange={onCountryChange}
                disabled={formProps.isCountryDisabled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!isFieldValueValid("country")}
                    label="Select Country"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.root}
            >
              {isInternal(engagementData.type)
                ? "Address"
                : "Engagement Address"}
            </Typography>
            <FormControl className={classes.formControl}>
              <TextField
                id="outlined-textarea"
                label="Location Address"
                name="address"
                value={engagementData.address}
                onChange={handleInputChange}
                multiline
                required
                rows={4}
                variant="outlined"
                error={
                  !isFieldValueValid("address") ||
                  isUserInputInvalid(engagementData.address)
                }
                inputProps={{ maxLength: 300 }}
              />
            </FormControl>
          </Grid>

          {engagementData.type ===
          FormConstants.EngagementTypes.MarketingEvent.id ? null : (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.root}
              >
                Business Unit
              </Typography>
              <FormControl
                required={
                  !(
                    engagementData.type ===
                    FormConstants.EngagementTypes.MarketingEvent.id
                  )
                }
                className={classes.formControl}
                variant="outlined"
                error={
                  !(
                    engagementData.type ===
                    FormConstants.EngagementTypes.MarketingEvent.id
                  ) && !isFieldValueValid("businessUnit")
                }
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Select Business Unit
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={engagementData.businessUnit}
                  name="businessUnit"
                  disabled={formProps.isBusinessUnitDisabled}
                  onChange={onBusinessUnitSelect}
                  label="Select Business Unit"
                >
                  {businessUnits &&
                    businessUnits.map((businessUnit) => (
                      <MenuItem
                        key={businessUnit.id}
                        value={businessUnit}
                        disabled={!businessUnit.isActive}
                      >
                        {businessUnit.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {engagementData.type ===
          FormConstants.EngagementTypes.MarketingEvent.id ? null : (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.root}
              >
                Product Unit
              </Typography>
              <FormControl
                required={
                  !(
                    engagementData.type ===
                    FormConstants.EngagementTypes.MarketingEvent.id
                  )
                }
                className={classes.formControl}
                variant="outlined"
                error={
                  !(
                    engagementData.type ===
                    FormConstants.EngagementTypes.MarketingEvent.id
                  ) && !isFieldValueValid("productUnit")
                }
              >
                <InputLabel id="demo-simple-select-helper-label">
                  Select Product Unit
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={engagementData.productUnit}
                  name="productUnit"
                  disabled={formProps.isProductUnitDisabled}
                  onChange={onProductUnitSelect}
                  label="Select Product Unit"
                >
                  {productUnits &&
                    productUnits
                      .filter((item) =>
                        isInternal(engagementData.type)
                          ? isMarketingEvent(engagementData.type)
                            ? item.isMarketing
                            : item.isInternal
                          : item.isCustomer
                      )
                      .filter((productUnitObj) =>
                        engagementData?.businessUnit?.id &&
                        productUnitObj.parentId
                          ? productUnitObj.parentId ===
                            engagementData.businessUnit.id
                          : true
                      )
                      .map((productUnitObj) => (
                        <MenuItem
                          key={productUnitObj.id}
                          value={productUnitObj}
                        >
                          {productUnitObj.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {isMarketingEvent(engagementData.type) ? (
            <Fragment>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography
                  variant="subtitle1"
                  align="left"
                  className={classes.root}
                >
                  Funding Sources
                </Typography>

                <Grid item xs={10}>
                  <FundingSource
                    engagementType={engagementData.type}
                    businessUnits={businessUnits}
                    productUnits={productUnits}
                    regions={regions}
                    subRegions={subRegions}
                    addedFundingSources={addedFundingSources}
                    setAddedFundingSources={setAddedFundingSources}
                    onFundingSourceChange={onFundingSourceChange}
                    isSubmitBtnClicked={isSubmitBtnClicked}
                  />
                </Grid>
                {/* <CircularProgressIndeterminate isLoading={isFundingSourcesLoading} /> */}
              </Grid>
            </Fragment>
          ) : null}

          {engagementData.type ===
          FormConstants.EngagementTypes.MarketingEvent.id ? null : (
            <>
              {
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="subtitle1"
                    align="left"
                    className={classes.root}
                  >
                    Sales Sub Region
                  </Typography>
                  <FormControl
                    required={
                      !(
                        engagementData.type ===
                        FormConstants.EngagementTypes.MarketingEvent.id
                      )
                    }
                    className={classes.formControl}
                    variant="outlined"
                    error={
                      !(
                        engagementData.type ===
                        FormConstants.EngagementTypes.MarketingEvent.id
                      ) && !isFieldValueValid("salesSubRegion")
                    }
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Sales Sub Region
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={engagementData.salesSubRegion}
                      name="salesSubRegion"
                      disabled={formProps.isSalesSubRegionDisabled}
                      onChange={onSubRegionSelect}
                      label="Sales Sub Region"
                    >
                      {subRegions &&
                        subRegions.map((salesSubRegion) => (
                          <MenuItem
                            key={salesSubRegion.id}
                            value={salesSubRegion}
                            disabled={!salesSubRegion.isActive}
                          >
                            {salesSubRegion.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              }
            </>
          )}

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Fragment>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.root}
              >
                Start Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={formProps.isStartEndDatesDisabled}
                  disableToolbar
                  inputVariant="outlined"
                  format="YYYY-MM-DD"
                  margin="normal"
                  id="date-picker-inline"
                  label="Start Date"
                  value={dayjs(engagementData.startDate)}
                  onChange={handleStartDateChange}
                  error={isDateError("startDate", engagementData.startDate)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  autoOk={true}
                  okLabel=""
                  minDate={
                    !isEditMode
                      ? dayjs(
                          getAllowedPastDateForEng(
                            isInternal(engagementData.type)
                          )
                        )
                      : null
                  }
                />
              </LocalizationProvider>
              <Typography
                variant="subtitle1"
                align="center"
                className={classes.root}
              >
                End Date
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disabled={formProps.isStartEndDatesDisabled}
                  disableToolbar
                  inputVariant="outlined"
                  format="YYYY-MM-DD"
                  margin="normal"
                  label="End Date"
                  id="date-picker-inline"
                  value={dayjs(engagementData.endDate)}
                  onChange={handleEndDateChange}
                  error={isDateError("endDate", engagementData.endDate)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  slotProps={{
                    textField: {
                      required: true,
                    },
                  }}
                  autoOk={true}
                  okLabel=""
                  minDate={
                    !isEditMode
                      ? dayjs(
                          getAllowedPastDateForEng(
                            isInternal(engagementData.type)
                          )
                        )
                      : null
                  }
                />
              </LocalizationProvider>
            </Fragment>
            {formProps.isTentativeDateAvail && !isEditMode && (
              <Fragment>
                <Typography
                  variant="subtitle1"
                  align="right"
                  className={classes.root}
                >
                  Tentative Dates
                </Typography>
                <FormControlLabel
                  name="isTentativeDates"
                  value={engagementData.isTentativeDates}
                  disabled={formProps.isTentativeDateDisabled}
                  control={
                    <Checkbox
                      checked={engagementData.isTentativeDates}
                      onChange={handleCheckboxChange}
                      disabled
                      color="primary"
                    />
                  }
                />
              </Fragment>
            )}

            {isEditMode && formProps.isFetchNewDatesButtonAvail && (
              <Fragment>
                <FormControl className={classes.btnControl}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={() => {
                      onClickSFetchNewDates();
                    }}
                  >
                    Fetch New Dates
                  </Button>
                </FormControl>
                <CircularProgressIndeterminate isLoading={isNewDatesFetching} />
              </Fragment>
            )}
          </Grid>

          {formProps.isCustomerContactAvail && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.root}
              >
                Customer Contact
              </Typography>
              <FormControl className={classes.formControl}>
                <TextField
                  id="outlined-textarea"
                  label="Name / Contact Number"
                  disabled={formProps.isCustomerContactDisabled}
                  multiline
                  required
                  rows={4}
                  variant="outlined"
                  name="customerContact"
                  value={engagementData.customerContact}
                  onChange={handleInputChange}
                  error={
                    !isFieldValueValid("customerContact") ||
                    isUserInputInvalid(engagementData.customerContact)
                  }
                />
              </FormControl>
            </Grid>
          )}

          {formProps.isBusinessDomainAvail && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.root}
              >
                Business Domain
              </Typography>
              <FormControl className={classes.formControl}>
                <TextField
                  id="filled-disabled"
                  disabled={formProps.isBusinessDomainDisabled}
                  label="Business Domain"
                  variant="outlined"
                  name="businessDomain"
                  value={engagementData.businessDomain}
                  onChange={handleInputChange}
                />
              </FormControl>
            </Grid>
          )}

          {formProps.isOpportunityOwnerAvail && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.root}
              >
                Opportunity Owner
              </Typography>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled
                  id="filled-disabled"
                  label="Opportunity Owner"
                  variant="outlined"
                  value={engagementData.opportunityOwner}
                />
              </FormControl>
            </Grid>
          )}

          {formProps.isTechnicalOwnerAvail && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.root}
              >
                Technical Owner
              </Typography>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled
                  id="filled-disabled"
                  label="Technical Owner"
                  variant="outlined"
                  value={engagementData.technicalOwner}
                />
              </FormControl>
            </Grid>
          )}

          {formProps.isContractSignedAvail && (
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.root}
              >
                Contracts Signed
              </Typography>
              <FormControl className={classes.formControl}>
                <FormControlLabel
                  name="isContractsSigned"
                  value={engagementData.isContractsSigned}
                  disabled={formProps.isContractSignedDisabled}
                  control={
                    <Checkbox
                      checked={engagementData.isContractsSigned}
                      onChange={handleCheckboxChange}
                      disabled
                      color="primary"
                    />
                  }
                />
              </FormControl>
            </Grid>
          )}

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.root}
            >
              Engagement Scope
            </Typography>
            <FormControl className={classes.formControl}>
              <TextField
                id="outlined-textarea"
                label="Type the scope here. If there are references please include G-Drive Links here"
                multiline
                rows={6}
                variant="outlined"
                name="engagementScope"
                value={engagementData.engagementScope}
                onChange={handleInputChange}
                error={isUserInputInvalid(engagementData.engagementScope)}
              />
            </FormControl>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.root}
            >
              Comments
            </Typography>
            <FormControl className={classes.formControl}>
              <TextField
                id="outlined-textarea"
                label="Any additional information"
                multiline
                rows={5}
                variant="outlined"
                name="comment"
                value={engagementData.comment}
                onChange={handleInputChange}
                error={isUserInputInvalid(engagementData.comment)}
              />
            </FormControl>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.root}
            ></Typography>
            <FormControl className={classes.btnControl}>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                disabled={isOnApprovalProcess ? false : isSubmitBtnDisabled}
                onClick={() => {
                  onClickSubmitBtn(true);
                }}
              >
                {buttonLable ? buttonLable : isEditMode ? "Update" : "Submit"}
              </Button>
            </FormControl>
            <FormControl className={classes.btnControl}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => {
                  onClickCancelBtn();
                }}
              >
                {isEditMode ? "Discard Changes" : "Cancel"}
              </Button>
            </FormControl>
          </Grid>
        </>
      )}

      <ConfirmationDialog
        data={confirmDialogDetails}
        open={
          Boolean(
            confirmDialogDetails && Object.values(confirmDialogDetails).length
          ) && confirmDialogDetails.open
        }
        handleSubmit={handleDialogBoxAction}
        dialogType={dialogType}
        disableCloseOnClickOutside={disableCloseOnClickOutside}
      />

      <AllocationsModalPopup
        data={allocationsPopupDetails}
        isInternalEngagement={isInternalEngagement}
        isMarketingEvent={
          engagementData.type ===
          FormConstants.EngagementTypes.MarketingEvent.id
        }
        requestStatus={RequestStatus.NEW}
        open={
          Boolean(
            allocationsPopupDetails &&
              Object.values(allocationsPopupDetails).length
          ) && allocationsPopupDetails.open
        }
        handleFormAction={handleInternalAllocationsSubmission}
        onCloseModalPopup={onCloseModalPopup}
      />

      <BackdropProgress open={isLoading} />
    </React.Fragment>
  );
};

export default EngagementForm;
