import { Fragment, useState } from "react";
import EngagementForm from "./EngagementForm";
import Portal from "@material-ui/core/Portal";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../Alert";

const EngagementEdit = (props) => {
  const [formProps, setFormProps] = useState({
    isAccountDisabled: true,
    isOpportunitySelectAvail: false,
    // isProductAvail: true,
    isSalesSubRegionDisabled: true,
    isProductUnitDisabled: false,
    isBusinessUnitDisabled: false,
    isStartEndDatesDisabled: true,
    isTentativeDateDisabled: true,
    isCustomerContactDisabled: false,
    isBusinessDomainDisabled: true,
    isContractSignedDisabled: true,
    isDescriptionDisabled: true,
    // isCustomerContactAvail: true,
    // isBusinessDomainAvail: true,
    // isOpportunityOwnerAvail: true,
    // isTechnicalOwnerAvail: true,
    // isContractSignedAvail: true,
    isFetchNewDatesButtonAvail: false,
    isCountryDisabled: true,
    isAllocationTypeDisabled: true,
  });

  const [isMessageBarOpen, setIsMessageBarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertMessageType, setAlertMessageType] = useState("error");

  const setMessageBar = (message, isOpen, type) => {
    setAlertMessage(message);
    setIsMessageBarOpen(isOpen);
    setAlertMessageType(type);
  };

  const handleMessageBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsMessageBarOpen(false);
    setAlertMessage("");
  };

  const isMarketingEvent = props.match.params.id.startsWith("EVT");

  const tabIndex = props.isInternal && !isMarketingEvent ? 0 : 1;

  return (
    <Fragment>
      <Portal>
        <Snackbar
          open={isMessageBarOpen}
          autoHideDuration={6000}
          onClose={handleMessageBarClose}
        >
          <Alert onClose={handleMessageBarClose} severity={alertMessageType}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Portal>
      <EngagementForm
        formTitle={
          props.isInternal
            ? "Edit Internal Engagement / Event"
            : "Edit Customer Engagement"
        }
        isInternalEngagement={Boolean(props.isInternal)}
        isEditMode={true}
        initialFormProps={formProps}
        engagementId={props.match.params.id}
        setMessageBar={setMessageBar}
        tabIndex={tabIndex}
        isOnApprovalProcess={false}
      />
    </Fragment>
  );
};

export default EngagementEdit;
